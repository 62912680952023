import { ModalName } from '../../models/modal';
import { ModalType } from './../../models/modal';
import { Component, OnDestroy } from '@angular/core';
import { ModalController } from '../../services/modal.controller';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss']
})

export class ModalWrapperComponent implements OnDestroy {

  public isShowModal;
  private type: ModalType;
  private modalControllerSubscription: Subscription;

  public complMessage = 'Запрос отправлен успешно';
  public testRequestMessage = `<b>Спасибо!</b> <br>
  В течение рабочего дня (ежедневно с 15 до 21) вышлем на телефон логин и пароль для тестирования сервиса.
  info@persik.by`;
  public errorMessage = 'Ошибка при отправке. Попробуйте позже';

  public istockMesage = `<b style="font-weight: bold">Спасибо за заявку!</b><br><br>
  Счёт на оплату по Вашей цене вышлем на почту в течение дня.<br>
  info@persik.by`;

  constructor(private modalCtrl: ModalController) {
    this.modalControllerSubscription = this.modalCtrl.modal.subscribe(res => {
      if (res) {
        this.isShowModal = true;
        this.type = res;
      } else {
        this.closeModal();
      }
    });
  }

  public closeModal(): void {
    this.isShowModal = false;
    this.type = null;
  }

  public get isLogin(): boolean {
    return this.type === ModalName.LOGIN;
  }

  public get isRegistration(): boolean {
    return this.type === ModalName.REGISTER;
  }

  public get isSendComplete(): boolean {
    return this.type === ModalName.SEND_COMPLETE || this.type === ModalName.TEST_REQUEST;
  }

  public get completeMessage(): string {
    return this.type === ModalName.TEST_REQUEST ? this.testRequestMessage : this.complMessage;
  }

  public get isSendError(): boolean {
    return this.type === ModalName.SEND_ERROR;
  }

  public get isForgot(): boolean {
    return this.type === ModalName.FORGOT;
  }

  public get isStockComplete(): boolean {
    return this.type === ModalName.ISTOCK_COMPLETE;
  }

  public get isVendorSend(): boolean {
    return this.type === ModalName.VENDOR_SEND;
  }

  public get isFastAuth(): boolean {
    return this.type === ModalName.FAST_AUTH;
  }

  ngOnDestroy() {
    if (this.modalControllerSubscription) {
      this.modalControllerSubscription.unsubscribe();
    }
  }
}
