<div class="forgot" (click)="stopProp($event)">
    <div class="modal-close" (click)="closeModal()">
        <img src="assets/images/icons/modal-close-icon.svg" alt="" class="modal-close__img">
    </div>
    <div class="modal-title">Восстановление пароля</div>
    <div class="modal-title_small" *ngIf="!isSuccess">Введите свой email и мы отправим Вам пароль на указанный почтовый адрес.</div>
    <div class="modal-title_small" *ngIf="isSuccess">Письмо с паролем отправлено на указанный почтовый ящик</div>
    <form *ngIf="!isSuccess" class="forgot__form" novalidate [formGroup]="forgotForm" (ngSubmit)="sendEmail()">
        <div class="form-group" [class.form-group_dirty]="email.value">
            <input id="email" type="text" formControlName="email" class="form-group__input" [class.has-error]="(email.dirty || email.touched) && email.errors">
            <label for="email" class="form-group__label">Email:</label>
            <div class="field-error" *ngIf="(email.dirty || email.touched) && email.errors">
                <span class="form-group__error" *ngIf="email.errors['required']">Поле обязательное для ввода</span>
                <span class="form-group__error" *ngIf="email.errors['emailValidator'] && !email.errors.required">Некорректно введен email</span>
                <span class="form-group__error" *ngIf="email.errors['emailExistValidator']">Пользователь с таким email не зарегистрирован в системе</span>
            </div>
        </div>

        <div class="modal-btns">
            <input type="submit" [disabled]="!forgotForm.valid" class="btn-orange" value="Отправить">
            <span (click)="goBack($event)" class="modal-btns__link modal-btns__link_center">Назад</span>
        </div>
    </form>
    <div class="modal-btns modal-btns_center" *ngIf="isSuccess">
        <button (click)="goBack($event)" class="btn-orange">ОК</button>
    </div>

</div>
