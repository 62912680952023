import { Component, ViewChild, ElementRef, HostListener, AfterViewInit, OnInit } from '@angular/core';
import { MetaService } from '../../services/meta.service';
import { meta } from './metadata';

@Component({
  selector: 'app-private-client',
  templateUrl: './private-client.component.html',
  styleUrls: ['./private-client.component.scss']
})
export class PrivateClientComponent implements AfterViewInit, OnInit {

  @ViewChild('stickyMenu') menuElement: ElementRef;

  public stickyHeader;
  public elementPosition: any;

  constructor(private metaService: MetaService) {}

  ngOnInit() {
    this.metaService.updateMeta(meta);
  }

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
    handleScroll() {
      const windowScroll = window.pageYOffset;
      if (windowScroll >= this.elementPosition) {
        this.stickyHeader = true;
      } else {
        this.stickyHeader = false;
      }
    }

  @HostListener('window:resize', ['$event'])
    handleResize() {
      this.stickyHeader = false;
    }

}
