import { map, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TimeService } from './time.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./time.service";
var DataService = /** @class */ (function () {
    function DataService(http, timeService) {
        this.http = http;
        this.timeService = timeService;
        this.BASE_URL = 'https://api.persik.by/';
        this.channels = [];
        this.banners = [];
        this.vodCategories = [];
    }
    DataService.prototype.loadChannels = function () {
        return this.http.get(this.BASE_URL.concat('v2/content/channels')).map(function (res) { return res.channels; }).toPromise();
    };
    DataService.prototype.getChannels = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.channels.length > 0) {
                resolve(_this.channels);
            }
            else {
                _this.loadChannels().then(function (res) {
                    _this.channels = res.sort(function (a, b) {
                        return b.rank - a.rank;
                    });
                    resolve(_this.channels);
                });
            }
        });
    };
    DataService.prototype.getChannelsForFeatured = function (genres) {
        var _this = this;
        return new Promise(function (resolve) {
            if (genres.length > 0) {
                var fChs_1 = [];
                _this.getChannels().then(function (chs) {
                    var _loop_1 = function (i) {
                        var channels = chs.filter(function (ch) { return ch.genres.includes(genres[i].id); });
                        if (channels && channels.length > 0) {
                            fChs_1.push({
                                title: genres[i].name,
                                channels: channels
                            });
                        }
                    };
                    for (var i = 0; i < genres.length; i++) {
                        _loop_1(i);
                    }
                    resolve(fChs_1);
                });
            }
            else {
                resolve(null);
            }
        });
    };
    DataService.prototype.loadFeatureChannels = function () {
        return this.http.get(this.BASE_URL.concat('v2/featured/channels?size=10')).map(function (res) { return res.channels; }).toPromise();
    };
    /* loadChannelCategories(): Promise<Genre[]> {
      return new Promise(resolve => {
        if (this.channelCategories.length > 0) {
          resolve(this.channelCategories);
        } else {
          this.http.get(this.BASE_URL.concat('v2/categories/channel')).map((categories: Genre[]) => {
            categories.unshift({
              id: -1,
              name: 'Все жанры',
              name_en: null,
              is_main: true
            });
            const existCategories: Genre[] = categories.filter(category => category.is_main);
            return existCategories;
          }).toPromise().then(res => {
            this.channelCategories = res;
            resolve(this.channelCategories);
          });
        }
      });
    } */
    DataService.prototype.loadChannelCategories = function () {
        if (!this.channelGenresCache) {
            this.channelGenresCache = this.http.get(this.BASE_URL.concat('v2/categories/channel')).pipe(publishReplay(1), refCount()).pipe(map(function (data) { return data.filter(function (genre) { return genre.is_main; }); })).pipe(map(function (data) {
                data.unshift({
                    id: 1,
                    name: 'Все жанры',
                    name_en: null,
                    is_main: true
                });
                return data;
            })).toPromise();
        }
        return this.channelGenresCache;
    };
    DataService.prototype.getCurrentTvShow = function (channelId) {
        var params = new HttpParams()
            .set('channels[]', channelId.toString());
        return this.http.get(this.BASE_URL.concat('v2/epg/onair'), { params: params }).pipe(map(function (response) {
            return response.tvshows[0];
        })).toPromise();
    };
    DataService.prototype.searchContent = function (text) {
        var params = new HttpParams()
            .set('query', text)
            .set('channels', 'true')
            .set('videos', 'true')
            .set('tvshows', 'true');
        return this.http.get(this.BASE_URL.concat('v2/search'), { params: params }).toPromise();
    };
    DataService.prototype.getTvshows = function (channelId) {
        var params = new HttpParams().set('channels[]', channelId.toString()).set('limit', '1000000');
        return this.http.get(this.BASE_URL.concat('v2/epg/tvshows'), { params: params })
            .pipe(map(function (response) { return response.tvshows.items; }))
            .toPromise();
    };
    DataService.prototype.getTodayTvshows = function (channelId) {
        var date = this.timeService.getDate();
        var lateDate = this.timeService.getThreeDaysAgoDate();
        var params = new HttpParams()
            .set('channels[]', channelId.toString())
            .set('from', lateDate)
            .set('to', date)
            .set('limit', '15');
        return this.http.get(this.BASE_URL.concat('v2/epg/tvshows'), { params: params })
            .pipe(map(function (response) { return response.tvshows.items; }))
            .toPromise();
    };
    DataService.prototype.getArchiveTvshows = function (channelId, skip) {
        var date = this.timeService.getDate();
        var lateDate = this.timeService.getArchiveDate();
        var time = this.timeService.getCurrentTime();
        var params = new HttpParams()
            .set('channels[]', channelId.toString())
            .set('from', lateDate)
            .set('to', date)
            .set('skip', skip.toString())
            .set('limit', '10000');
        return this.http.get(this.BASE_URL.concat('v2/epg/tvshows'), { params: params })
            .pipe(map(function (response) { return response.tvshows.items.filter(function (tvshow) { return tvshow.start < time && tvshow.stop < time; }); }))
            .toPromise();
    };
    DataService.prototype.getVideoInfo = function (id) {
        var params = new HttpParams()
            .set('id[]', id.toString());
        return this.http.get(this.BASE_URL.concat('v2/content/video'), { params: params }).pipe(map(function (res) {
            if (res && res.videos) {
                return res.videos[0];
            }
            return null;
        })).toPromise();
    };
    DataService.prototype.getVideosInfo = function (ids) {
        var params = new HttpParams();
        ids.forEach(function (element) {
            params = params.append('id[]', element);
        });
        return this.http.get(this.BASE_URL.concat('v2/content/video'), { params: params }).pipe(map(function (res) {
            if (res && res.videos) {
                return res.videos;
            }
            return null;
        })).toPromise();
    };
    DataService.prototype.getSimilar = function (id) {
        var params = new HttpParams()
            .set('id', id.toString());
        return this.http.get(this.BASE_URL.concat('v2/featured/similar'), { params: params }).pipe(map(function (res) {
            if (res && res.videos) {
                return res.videos;
            }
            return null;
        })).toPromise();
    };
    DataService.prototype.getTvshowInfo = function (id) {
        var params = new HttpParams()
            .set('id[]', id);
        return this.http.get(this.BASE_URL.concat('v2/content/tvshow'), { params: params })
            .pipe(map(function (res) { return res.tvshows[0]; })).toPromise();
    };
    DataService.prototype.loadVodFeatured = function () {
        var _this = this;
        return this.http.get(this.BASE_URL.concat('v2/featured')).map(function (featured) {
            featured.sections.forEach(function (section) {
                _this.loadVodHomeContent(section.source).then(function (res) {
                    // res.videos.push({ video_id: -1 });
                    Object.assign(section, res);
                });
            });
            return featured;
        }).toPromise();
    };
    DataService.prototype.loadVodHomeContent = function (source) {
        var params = new HttpParams()
            .set('size', source.params.size.toString())
            .set('category_id', source.params.category_id ? source.params.category_id.toString() : '')
            .set('genre_id', source.params.genre_id ? source.params.genre_id.toString() : '')
            .set('sort', 'last');
        return this.http.get(this.BASE_URL.concat("v" + source.version + "/" + source.module + "/" + source.action), { params: params })
            .toPromise();
    };
    DataService.prototype.getChannelById = function (id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.channels.length > 0) {
                var channel = _this.channels.find(function (ch) { return ch.channel_id === id; });
                if (channel) {
                    resolve(channel);
                }
                else {
                    _this.loadChannels().then(function (res) {
                        _this.channels = res;
                        var ch_f = _this.channels.find(function (ch) { return ch.channel_id === id; });
                        resolve(ch_f);
                    });
                }
            }
            else {
                _this.loadChannels().then(function (res) {
                    _this.channels = res;
                    var ch_f = _this.channels.find(function (ch) { return ch.channel_id === id; });
                    resolve(ch_f);
                });
            }
        });
    };
    DataService.prototype.loadChannel = function (id) {
        var paramString = "id[]=" + id;
        return this.http.get(this.BASE_URL.concat('v2/content/channel?', paramString)).map(function (res) { return res.channels[0]; }).toPromise();
    };
    DataService.prototype.loadVodCategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.vodCategories.length > 0) {
                resolve(_this.vodCategories);
            }
            else {
                _this.http.get(_this.BASE_URL.concat('v2/categories/video')).map(function (categories) {
                    categories.forEach(function (category) {
                        if (category.id === 6) {
                            category.genres.unshift({
                                id: 1,
                                name: 'Все жанры',
                                name_en: null,
                                is_main: true
                            });
                        }
                    });
                    return categories;
                }).toPromise().then(function (res) {
                    _this.vodCategories = res;
                    resolve(_this.vodCategories);
                });
            }
        });
    };
    DataService.prototype.loadActors = function (ids) {
        var paramString = '';
        ids.forEach(function (id) {
            paramString += "id[]=" + id + "&";
        });
        paramString.slice(-1);
        return this.http.get(this.BASE_URL.concat('v2/content/person?', paramString)).pipe(map(function (response) {
            return response.persons;
        })).toPromise();
    };
    DataService.prototype.getVideoContent = function (category_id, genre_id, size, skip, country, sort) {
        var type = 'sort';
        if (sort && sort.value.split(' ').length === 2) {
            type = 'year';
        }
        var params = new HttpParams()
            .set('size', (size && size !== 0) ? size.toString() : '')
            .set('offset', (skip && skip !== 0) ? skip.toString() : '')
            .set('category_id', category_id.toString())
            .set('genre_id', (genre_id !== 1 && genre_id) ? genre_id.toString() : '')
            .set('country_id', (country && country.id !== 0) ? country.id.toString() : '')
            .set('sort', (type === 'sort' && sort && sort.value) ? sort.value : 'last')
            .set('year_from', (type === 'year') ? sort.value.split(' ')[0] : '')
            .set('year_to', (type === 'year') ? sort.value.split(' ')[1] : '');
        return this.http.get(this.BASE_URL.concat('v2/content/videos'), { params: params }).toPromise();
    };
    DataService.prototype.getChannelStream = function (id) {
        var params = new HttpParams().set('id', id.toString());
        return this.http.get(this.BASE_URL.concat('v1/stream/channel'), { params: params }).toPromise();
    };
    DataService.prototype.getVideoStream = function (id) {
        var params = new HttpParams().set('id', id.toString());
        return this.http.get(this.BASE_URL.concat('v1/stream/video'), { params: params }).toPromise();
    };
    DataService.prototype.getTvshowStream = function (id) {
        var params = new HttpParams().set('id', id);
        return this.http.get(this.BASE_URL.concat('v1/stream/show'), { params: params }).toPromise();
    };
    DataService.prototype.getBanners = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.banners.length > 0) {
                resolve(_this.banners);
            }
            else {
                _this.http.get(_this.BASE_URL.concat('v2/content/banners2')).map(function (res) {
                    if (res) {
                        return res.filter(function (banner) { return banner.trailer_url_desktop.length > 0; });
                    }
                }).toPromise().then(function (response) {
                    _this.banners = response;
                    resolve(_this.banners);
                });
            }
        });
    };
    DataService.prototype.getBannersForBook = function () {
        return this.http.get(this.BASE_URL.concat('v2/content/banners2'))
            .map(function (res) { return res.filter(function (banner) { return Number(banner.banner_id) === 121 || Number(banner.banner_id) === 122; }); }).toPromise(); // очередной костыль, пишу и слезы наворачиваются
    };
    DataService.prototype.getCountryList = function (category_id) {
        var params = new HttpParams().set('id', category_id.toString());
        return this.http.get(this.BASE_URL.concat('v2/content/countries'), { params: params }).map(function (response) {
            var countries = [];
            countries.push({
                id: 0,
                name: 'Все страны'
            });
            for (var id in response.countries) {
                if (id) {
                    countries.push({
                        id: +id,
                        name: response.countries[id]
                    });
                }
            }
            return countries;
        }).toPromise();
    };
    DataService.prototype.sendPublishFormData = function (data) {
        var params = new HttpParams()
            .set('name', data.name)
            .set('email', data.email)
            .set('phone', data.phone)
            .set('message', data.message)
            .set('page', data.page)
            .set('business', data.business ? data.business : '');
        return this.http.post(this.BASE_URL.concat('v2/forms/request'), {}, { params: params }).toPromise();
    };
    DataService.prototype.getTariffs = function () {
        return this.http.get(this.BASE_URL.concat('v2/billing/products'));
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(i0.inject(i1.HttpClient), i0.inject(i2.TimeService)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
