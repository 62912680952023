import { FormControl } from '@angular/forms';
import { AuthService } from '../services/auth.service';

let checkTimer: any;

export function emailNotExistValidator(authService: AuthService) {

  return (control: FormControl) => {
    return new Promise(resolve => {
      clearTimeout(checkTimer);
      checkTimer = setTimeout(() => {
        if (!control.errors) {
          authService.checkEmail(control.value).then(response => {
            if (response.exists) {
              resolve({
                emailNotExistValidator: {
                  exist: true
                }
              });
            } else {
              resolve(null);
            }
          }).catch();
        }
      }, 2000);
    });
  };

}
