<div class="search search_dark" [class.search_open]="isOpen" [class.search_light]="!isDark" (click)="clickedInside($event)">
    <div class="search__content">
        <div class="search__main-btn" (click)="openSearch()">
            <img src="assets/images/icons/search-icon.svg" class="search__icon" *ngIf="!isDark || isOpen"  alt="Поиск">
            <img src="assets/images/icons/search-icon-white.svg" class="search__icon" *ngIf="isDark && !isOpen"  alt="Поиск">
        </div>
        <input type="text" class="search__input" #searchInput [(ngModel)]="inputValue" (keyup.enter)="makeSearch()">
        <div class="search__clear" (click)="clearSearch()">×</div>

    </div>

</div>
