import { Subject } from 'rxjs/Subject';
import { DataService, TranslitService, RouterBackService, MetaService } from './../../services';
import 'rxjs/add/operator/map';
import { ContentName, ControlEvents, PlayerEvents } from './../../models';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlayerComponent } from '../../components/player';
import { Location, isPlatformBrowser } from '@angular/common';
import { setContentName } from './metadata';
var ChannelPlayerComponent = /** @class */ (function () {
    function ChannelPlayerComponent(activatedRoute, location, translitService, routerBackService, dataService, platform, metaService) {
        this.activatedRoute = activatedRoute;
        this.location = location;
        this.translitService = translitService;
        this.routerBackService = routerBackService;
        this.dataService = dataService;
        this.platform = platform;
        this.metaService = metaService;
        this.isPlaying = false;
        this.isCanPlay = false;
        this.isParentControlNeeded = false;
        this.checkPinEvent = new Subject();
        this.isHideControls = false;
        this.isBrowser = false;
    }
    ChannelPlayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var params = this.activatedRoute.snapshot.params;
        var urlSplit = params['id'].split('-');
        this.channel_id = +urlSplit[urlSplit.length - 1];
        if (isPlatformBrowser(this.platform)) {
            this.isBrowser = true;
            setTimeout(function () {
                _this.initPlayerBehaviors();
            }, 1000);
        }
        this.showControlsWithAutohide();
    };
    ChannelPlayerComponent.prototype.showControlsWithAutohide = function () {
        var _this = this;
        this.isHideControls = false;
        clearTimeout(this.autohideTimer);
        this.autohideTimer = setTimeout(function () {
            _this.isHideControls = true;
        }, 5000);
    };
    Object.defineProperty(ChannelPlayerComponent.prototype, "volume", {
        get: function () {
            if (this.player) {
                return this.player.volume * 100;
            }
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    ChannelPlayerComponent.prototype.toggleFullscreen = function () {
        if (this.isFullscreenActive) {
            this.closeFullscreen();
        }
        else {
            this.openFullscreen();
        }
    };
    Object.defineProperty(ChannelPlayerComponent.prototype, "isFullscreenActive", {
        get: function () {
            return ((document['fullScreenElement'] && document['fullScreenElement']
                !== null) || document['mozFullScreen'] || document['webkitIsFullScreen']);
        },
        enumerable: true,
        configurable: true
    });
    ChannelPlayerComponent.prototype.openFullscreen = function () {
        var elem = document.body;
        var methodToBeInvoked = elem['requestFullscreen'] || elem['mozRequestFullScreen'] || elem['webkitRequestFullscreen'];
        if (methodToBeInvoked) {
            methodToBeInvoked.call(elem);
        }
    };
    ChannelPlayerComponent.prototype.closeFullscreen = function () {
        var elem = document;
        var methodToBeInvoked = elem['cancelFullScren'] || elem['mozCancelFullScreen'] || elem['webkitCancelFullScreen'];
        if (methodToBeInvoked) {
            methodToBeInvoked.call(elem);
        }
    };
    ChannelPlayerComponent.prototype.initPlayerBehaviors = function () {
        var _this = this;
        this.playerEventsSubscriber = this.player.playerEvents.subscribe(function (event) {
            switch (event) {
                case PlayerEvents.PLAYER_READY:
                    _this.isPlaying = true;
                    _this.isCanPlay = true;
                    break;
                case PlayerEvents.PLAYER_PAUSE:
                    _this.isPlaying = false;
                    break;
                case PlayerEvents.PLAYER_PLAY:
                    _this.isPlaying = true;
                    break;
                case PlayerEvents.PLAYER_ERROR_SUBSCRIPTION:
                    _this.isCanPlay = false;
                    break;
                case PlayerEvents.PLAYER_ERROR_LOGIN:
                    _this.isCanPlay = false;
                    break;
                default:
                    break;
            }
        });
    };
    ChannelPlayerComponent.prototype.onControlEvent = function (event) {
        switch (event.name) {
            case ControlEvents.CONTROL_PAUSE:
                this.player.pause();
                break;
            case ControlEvents.CONTROL_PLAY:
                this.player.resume();
                break;
            case ControlEvents.CONTROL_VOLUME:
                this.player.volume = event.value / 100;
                break;
            default:
                break;
        }
    };
    ChannelPlayerComponent.prototype.onAdultCheckerComplete = function (result) {
        if (result) {
            this.checkPinEvent.next(true);
        }
        this.isParentControlNeeded = false;
    };
    ChannelPlayerComponent.prototype.goBack = function () {
        if (this.isFullscreenActive) {
            this.closeFullscreen();
        }
        this.routerBackService.goBack();
    };
    ChannelPlayerComponent.prototype.changeActiveChannel = function (channel) {
        this.channel_id = channel.channel_id;
        this.selectedChannelId = channel.channel_id;
        var cpu = this.translitService.convertCyrillToLatin(channel.name);
        this.location.go("channel-player/" + cpu + "-" + channel.channel_id);
        this.player.stop();
        this.playChannel();
    };
    ChannelPlayerComponent.prototype.changeSelectedChannel = function (channel_id) {
        this.selectedChannelId = channel_id;
    };
    ChannelPlayerComponent.prototype.playChannel = function () {
        var _this = this;
        this.dataService.getChannelById(this.channel_id).then(function (channel) {
            var meta = setContentName(channel.name);
            _this.metaService.updateMeta(meta);
            if (!channel.age_rating.includes('18')) {
                _this.isParentControlNeeded = false;
                if (_this.checkPinSubscriber) {
                    _this.checkPinSubscriber.unsubscribe();
                }
                if (channel.genres.includes(1299)) {
                    _this.player.play(_this.channel_id, ContentName.RADIO);
                }
                else {
                    _this.player.play(_this.channel_id, ContentName.CHANNEL);
                }
            }
            else {
                _this.isParentControlNeeded = true;
                _this.checkPinSubscriber = _this.checkPinEvent.subscribe(function () {
                    _this.player.play(_this.channel_id, ContentName.CHANNEL);
                });
            }
        });
    };
    ChannelPlayerComponent.prototype.ngOnDestroy = function () {
        this.player.stop();
        if (this.playerEventsSubscriber) {
            this.playerEventsSubscriber.unsubscribe();
        }
        if (this.checkPinSubscriber) {
            this.checkPinSubscriber.unsubscribe();
        }
        this.closeFullscreen();
    };
    return ChannelPlayerComponent;
}());
export { ChannelPlayerComponent };
