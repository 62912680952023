<div class="main-wrapper">
  <!--*ngIf="isShowVendorModal && isRetailPage"-->
  <div
    class="header__top"
    [class.header__top_dark]="isDark"
    *ngIf="!isPlayer && !isRetailAuth"
    [class.header-retail]="isRetailPage || isRetailAdmin"
  >
    <div class="header__btn" (click)="toggleMobileNav()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        width="20px"
        height="20px"
        viewBox="0 0 459 459"
        style="enable-background: new 0 0 459 459"
        xml:space="preserve"
      >
        <g>
          <g id="menu">
            <path
              d="M0,382.5h459v-51H0V382.5z M0,255h459v-51H0V255z M0,76.5v51h459v-51H0z"
              fill="#232323"
            />
          </g>
        </g>
      </svg>
    </div>
    <div
      class="header__logo"
      [class.header__logo_retail]="isRetailPage || isRetailAdmin"
      routerLink="/"
      *ngIf="!isRetailPage && !isRetailAdmin && !isTvGuide"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="120px"
        viewBox="0 0 163 47"
      >
        <defs>
          <linearGradient id="q" x1="100%" x2="0%" y1="50%" y2="50%">
            <stop offset="0%" stop-color="#FE6F41" />
            <stop offset="100%" stop-color="#FFCD78" />
          </linearGradient>
          <linearGradient id="w" x1="100%" x2="0%" y1="50%" y2="50%">
            <stop offset="0%" stop-color="#9ED061" />
            <stop offset="100%" stop-color="#ABE06A" />
          </linearGradient>
          <path id="e" d="M18.598.608v20l16-10.821z" />
          <filter
            id="r"
            width="187.5%"
            height="170%"
            x="-43.8%"
            y="-25%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="2"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.245272871 0"
            />
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <path
            fill="url(#q)"
            d="M97.27 26h-3.633V11.89h-4.653V8.868h12.938v3.024H97.27V26zm17.062-17.133H118L112.176 26h-3.961l-5.813-17.133h3.668l3.223 10.195c.18.602.365 1.303.557 2.104.191.8.31 1.357.357 1.67.086-.719.379-1.977.879-3.773l3.246-10.196z"
            transform="translate(45 11)"
          />
          <text
            fill="#232323"
            font-family="OpenSans-Bold"
            font-size="24"
            font-weight="bold"
            transform="translate(45 11)"
          >
            <tspan x="0" y="26">PERSIK</tspan>
          </text>
          <g>
            <path
              fill="url(#q)"
              d="M18 45.342c-9.941 0-18-8.06-18-18 0-9.941 8.059-18 18-18s18 8.059 18 18c0 9.94-8.059 18-18 18zm0-10.957a7.043 7.043 0 1 0 0-14.087 7.043 7.043 0 0 0 0 14.087z"
              transform="translate(0 1)"
            />
            <g transform="translate(0 1)">
              <use fill="#000" filter="url(#r)" xlink:href="#e" />
              <use fill="url(#w)" xlink:href="#e" />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="header__phones">
      <a
        href="mailto:head@persik.tv"
        class="header__phones-item"
        [class.header__phones-item--dark]="isDark"
      >
        head@persik.tv
      </a>
    </div>

    <div
      class="stock"
      [class.stock_dark]="isDark"
      routerLink="private/tv-tarify"
      fragment="god-mounth"
    >
      <b>30</b>дней<b class="bright">за 30 копеек</b>
    </div>

    <div class="header__clients">
      <a
        routerLink="private/tv-tarify"
        [class.header__clients-item--dark]="isDark"
        [class.header__clients-item_active]="isPrivateClientPage"
        class="header__clients-item"
      >
        Тарифы и каналы
      </a>
      <a
        routerLink="/"
        [class.header__clients-item--dark]="isDark"
        [class.header__clients-item_active]="isOnlinePage"
        class="header__clients-item"
      >
        Онлайн-просмотр
      </a>
      <a
        routerLink="private/tv-tarify"
        class="header__clients-item"
        [class.header__clients-item_active]="isCorporateClient"
        [class.header__clients-item--dark]="isDark"
      >
        Частным клиентам
      </a>
    </div>

    <div class="header__btns" *ngIf="!isRetailPage && !isRetailAdmin">
      <a
        *ngIf="isBrowser && isLogin"
        routerLink="/izbrannoe"
        class="header__btns-item header__btn-login"
      >
        <img
          class="header__btns-item_img"
          src="assets/images/icons/star-icon.svg"
          alt="Избранное"
          *ngIf="isDark"
        />
        <img
          class="header__btns-item_img"
          src="assets/images/icons/star-icon-orange.svg"
          alt="Избранное"
          *ngIf="!isDark"
        />
      </a>

      <app-search-component [isDark]="isDark"></app-search-component>

      <a
        (click)="showLoginModal()"
        (click)="closeMobileNav()"
        class="header__btns-item header__btn-login"
      >
        <img
          src="assets/images/icons/login-icon.svg"
          *ngIf="!isDark"
          alt="Вход"
        />
        <img
          src="assets/images/icons/login-icon-white.svg"
          *ngIf="isDark"
          alt="Вход"
        />
      </a>
    </div>

    <div class="retail-btns" *ngIf="isRetailPage || isRetailAdmin">
      <div
        class="retail-btns__vendor"
        (click)="showVendorModal()"
        *ngIf="!isRetailAdmin"
      >
        Написать вендору
      </div>
      <div class="retail-btns__exit" (click)="exitRetail()">Выход</div>
    </div>
  </div>

  <div
    class="mobile-nav-wrapper"
    (click)="closeMobileNav()"
    [class.mobile-nav-wrapper_dark]="isDark"
    [class.mobile-nav-wrapper_active]="isModalNav"
  ></div>
  <div
    class="mobile-nav"
    [class.mobile-nav_dark]="isDark"
    [class.mobile-nav_opened]="isModalNav"
  >
    <div class="mobile-nav__menu" (click)="toggleMobileNav()">
      <div
        class="mobile-nav__item"
        routerLink="private/tv-tarify"
        routerLinkActive="mobile-nav__item_active"
      >
        <div class="mobile-nav__item-wrapper">Тарифы и каналы</div>
      </div>
      <div
        class="mobile-nav__item"
        [routerLink]="['']"
        [class.mobile-nav__item_opened]="mobileNavDropdown.online"
        [class.mobile-nav__item_active]="isActiveOnline"
      >
        <div class="mobile-nav__item-wrapper">
          Онлайн-просмотр
          <div
            *ngIf="isBrowser"
            class="mobile-nav__item-icon"
            (click)="toggleMobileDropdown($event, 'online')"
          ></div>
        </div>
      </div>
      <div class="mobile-nav__container" *ngIf="mobileNavDropdown.online">
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['./']"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">ТВ Онлайн</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['/tv-guide']"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Программа передач</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['/films']"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Фильмы</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['/serialy']"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Сериалы</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['/multfilmy']"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Мультфильмы</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          routerLink="/peredachi"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Передачи</div>
        </div>

        <div
          class="mobile-nav__item mobile-nav__item_sub"
          routerLink="/blogers"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Блоги</div>
        </div>
      </div>
      <div
        class="mobile-nav__item"
        [routerLink]="['/izbrannoe']"
        routerLinkActive="mobile-nav__item_active"
        *ngIf="isLogin"
      >
        <div class="mobile-nav__item-wrapper">Избранное</div>
      </div>
      <div
        class="mobile-nav__item"
        [routerLink]="['private/devices']"
        [class.mobile-nav__item_opened]="mobileNavDropdown['private/devices']"
        [class.mobile-nav__item_active]="isActiveDevices"
        (click)="toggleMobileDropdown($event, 'private/devices')"
      >
        <div class="mobile-nav__item-wrapper">
          Как смотреть
          <div
            *ngIf="isBrowser"
            class="mobile-nav__item-icon"
            (click)="toggleMobileDropdown($event, 'private/devices')"
          ></div>
        </div>
      </div>

      <div
        class="mobile-nav__container"
        *ngIf="mobileNavDropdown['private/devices']"
      >
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          (click)="goTo('private/devices', 'smart')"
        >
          <div class="mobile-nav__item-wrapper">Телевизоры</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          (click)="goTo('private/devices', 'laptop')"
        >
          <div class="mobile-nav__item-wrapper">Компьютеры и ноутбуки</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          (click)="goTo('private/devices', 'mobile')"
        >
          <div class="mobile-nav__item-wrapper">Смартфоны и планшеты</div>
        </div>
      </div>

      <div
        class="mobile-nav__item"
        [class.mobile-nav__item_opened]="mobileNavDropdown['private/user']"
        [class.mobile-nav__item_active]="isActiveTextPages"
        (click)="toggleMobileDropdown($event, 'private/user')"
      >
        <div class="mobile-nav__item-wrapper">
          Пользователю
          <div
            *ngIf="isBrowser"
            class="mobile-nav__item-icon"
            (click)="toggleMobileDropdown($event, 'private/user')"
          ></div>
        </div>
      </div>

      <div
        class="mobile-nav__container"
        *ngIf="mobileNavDropdown['private/user']"
      >
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['faq']"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Часто задаваемые вопросы</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['paymethods']"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Способы оплаты</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['watch-free']"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Как смотреть бесплатно</div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['offer']"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">
            Пользовательское соглашение
          </div>
        </div>
        <div
          class="mobile-nav__item mobile-nav__item_sub"
          [routerLink]="['speedtest']"
          routerLinkActive="mobile-nav__item_active"
        >
          <div class="mobile-nav__item-wrapper">Проверить скорость</div>
        </div>
      </div>

      <div
        class="mobile-nav__item"
        routerLink="private/user"
        routerLinkActive="mobile-nav__item_active"
      >
        <div class="mobile-nav__item-wrapper">Личный кабинет</div>
      </div>
      <div
        class="mobile-nav__item"
        [routerLink]="['private/contacts']"
        routerLinkActive="mobile-nav__item_active"
      >
        <div class="mobile-nav__item-wrapper">Контакты</div>
      </div>
    </div>

    <div class="mobile-nav__footer">
      <div class="mobile-nav__footer-item">info@persik.by</div>
    </div>
  </div>

  <div
    class="to-top-btn"
    *ngIf="isHaveMovement"
    (click)="scrollToTop()"
    [class.to-top-btn_tariffs]="isTariffsPage"
  >
    <img
      src="assets/images/icons/white-arrow.svg"
      alt=""
      class="to-top-btn__image"
    />
  </div>

  <router-outlet></router-outlet>

  <app-footer *ngIf="!isPlayer"></app-footer>

  <app-preloader *ngIf="isLoading"></app-preloader>

  <app-ya-metrika-modal *ngIf="isBrowser"></app-ya-metrika-modal>

  <app-modal-wrapper></app-modal-wrapper>

  <!-- <app-sale-modal *ngIf="isShowSaleModal" (closeEvent)="closeSale()"></app-sale-modal> -->
</div>
