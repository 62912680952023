import { Injectable } from '@angular/core';
import { ModalType } from '../models/modal';
import { Subject } from 'rxjs';

@Injectable()
export class ModalController {

  public modal: Subject<ModalType> = new Subject();
  public complete_modal: Subject<ModalType> = new Subject();

  constructor() {}

  public present(type: ModalType): void {
    this.modal.next(type);
  }

  public destroy(): void {
    this.modal.next();
  }

}
