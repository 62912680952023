import { MetaDefinition } from '@angular/platform-browser';
import { MetaData } from '../../models/meta';

const tags: MetaDefinition[] = [
  {
    name: 'keywords',
    content: 'подключить телевидение, подключить тв, цифровое телевидение, цифровое ТВ, онлайн-тв, интернет-телевидение'
  },
  {
    name: 'description',
    content: `Подключайте телевидение (IPTV) Персик ТВ в ▶ Минске, ▶ Бресте, ▶ Гродно, ▶ Витебске, ▶ Могилеве и ▶ Гомеле.
      ✅Первый месяц всего за 30 копеек! ✅ Архив 24 дня, Full HD качество, 100 каналов, программа передач.
      ✅Возможность смотреть одновременно на разных устройствах. info@persik.by`
  }
];

const title = 'Подключить интернет-телевидение TV Persik - смотреть ТВ онлайн в хорошем качестве';

export const meta: MetaData = {
  title,
  tags
};
