<div class="footer" [class.footer--dark]="isDark" *ngIf="!isTvGuide">
  <div class="green-circle"></div>
  <div class="green-dot dot1"></div>
  <div class="green-dot dot2"></div>
  <div class="container">
    <div class="row">
      <div class="footer-col hidden-mobile">
        <div class="footer__title" (click)="goTo('')">Пользователю</div>
        <a (click)="goTo('private/tv-tarify')" class="footer__item">
          <!-- *ngIf="!isRetailPage && !isRetailAdmin" -->
          Тарифы и каналы
        </a>
        <a (click)="goTo('faq')" class="footer__item">
          Часто задаваемые вопросы
        </a>
        <a (click)="goTo('paymethods')" class="footer__item">
          Способы оплаты
        </a>
        <a (click)="goTo('bank-card')" class="footer__item">
          Оплата банковской картой
        </a>
        <a (click)="goTo('watch-free')" class="footer__item">
          Как смотреть бесплатно
        </a>
        <a (click)="goTo('offer')" class="footer__item">
          Пользовательское соглашение
        </a>
        <a (click)="goTo('speedtest')" class="footer__item">
          Проверить скорость
        </a>
      </div>

      <div class="footer-col hidden-mobile">
        <!--  *ngIf="!isRetailPage" -->
        <div class="footer__title" (click)="goTo('')">Онлайн-просмотр</div>
        <a class="footer__item" (click)="goTo('tv-review')"> ТВ </a>
        <a class="footer__item" (click)="goTo('films')"> Фильмы </a>
        <a class="footer__item" (click)="goTo('serialy')"> Сериалы </a>
        <a class="footer__item" (click)="goTo('peredachi')"> Передачи </a>
        <a class="footer__item" (click)="goTo('multfilmy')"> Мультфильмы </a>
        <a class="footer__item" (click)="goTo('kursy-online')"> Курсы </a>
        <a class="footer__item" (click)="goTo('audio-books')"> Аудиокниги </a>
        <a class="footer__item" (click)="goTo('radio')">
          Радио из сети интернет
        </a>
        <a class="footer__item" (click)="goTo('books')"> Книги </a>
        <a class="footer__item" (click)="goTo('games')"> Игры </a>
      </div>

      <div class="footer-col hidden-mobile">
        <div class="footer__title" (click)="goTo('private/devices')">
          Смотреть на устройствах
        </div>
        <a class="footer__item" (click)="goTo('private/devices', 'smart')">
          Телевизоры
        </a>
        <a class="footer__item" (click)="goTo('private/devices', 'laptop')">
          Компьютеры и ноутбуки
        </a>
        <a class="footer__item" (click)="goTo('private/devices', 'mobile')">
          Смартфоны и планшеты
        </a>

        <div class="footer__title mt">Сотрудничество</div>
        <a
          class="footer__item"
          (click)="goTo('private/publish-online-courses')"
        >
          С авторами онлайн-курсов
        </a>
        <a class="footer__item" (click)="goTo('private/publish-media-content')">
          С владельцами медиаконтента
        </a>
        <a class="footer__item" (click)="goTo('private/sotrudnichestvo')">
          С дилерами
        </a>

        <div
          class="footer__title mt"
          (click)="goTo('private/reklama-na-persik-tv')"
        >
          Реклама на Persik TV
        </div>
      </div>

      <!-- <div class="footer-col hidden-mobile">
				<div class="footer__title">
					Наши проекты
				</div>
				<a class="footer__item" href="https://edu.persik.by/" target="_blank">
					<img src="assets/images/edu.png" alt="">
				</a>
				<a class="footer__item" href="http://shop.persik.by/" target="_blank">
					<img src="assets/images/shop.png" alt="">
				</a>
			</div> -->

      <div class="footer-col">
        <div class="footer__title" (click)="goTo('private/contacts')">
          контакты
        </div>
        <div class="footer__item footer__item--no-hover">
          Минск, пр-т Победителей, д. 100, ком. 104/2
        </div>
        <a
          href="mailto:info@persik.by"
          class="footer__item footer__item--no-hover footer__item_cursor"
        >
          info@persik.by
        </a>
      </div>

      <div class="footer-col">
        <div class="footer__small-title footer__title">
          <!--  [class.footer__small-title]="!isRetailPage" -->
          <!-- [class.footer__title]="isRetailPage" -->
          Присоединяйтесь:
        </div>
        <div class="footer__social">
          <a
            href="https://vk.com/persiktv"
            target="_blank"
            class="footer__social-item"
          >
            <img src="assets/images/icons/vk-icon.svg" alt="" />
          </a>
          <a
            href="https://www.facebook.com/PersikTV1/"
            target="_blank"
            class="footer__social-item"
          >
            <img src="assets/images/icons/facebook-icon.svg" alt="" />
          </a>
        </div>
      </div>

      <div class="footer-col">
        <div class="footer__small-title">Наши приложения:</div>
        <div class="footer__markets">
          <a
            href="https://play.google.com/store/apps/details?id=by.persik.android2&hl=ru"
            target="_blank"
            class="footer__markets-item"
          >
            <img
              src="assets/images/google.png"
              alt=""
              class="footer__markets-img"
            />
          </a>
          <a
            class="footer__markets-item"
            (click)="goTo('private/devices', 'smart')"
          >
            <img
              src="assets/images/smart-tv.png"
              alt=""
              class="footer__markets-img"
            />
          </a>
        </div>
      </div>

      <div class="footer-col">
        <div class="footer__small-title">Наши проекты</div>
        <div class="footer__markets">
          <a
            class="footer__markets-item"
            href="https://edu.persik.by/"
            target="_blank"
            rel="nofollow"
          >
            <img
              src="assets/images/edu.jpg"
              alt=""
              class="footer__markets-img"
            />
          </a>
          <a
            class="footer__markets-item"
            href="http://shop.persik.by/"
            target="_blank"
            rel="nofollow"
          >
            <img
              src="assets/images/shop.jpg"
              alt=""
              class="footer__markets-img"
            />
          </a>
        </div>
      </div>

      <div class="footer-col">
        <div class="footer__small-title">Способы оплаты:</div>
        <div class="footer__payments">
          <div class="footer__payments-item">
            <img
              src="assets/images/footer-payments/assist.png"
              alt=""
              class="footer__payments-img"
            />
          </div>
          <div class="footer__payments-item">
            <img
              src="assets/images/footer-payments/belcard_int.png"
              alt=""
              class="footer__payments-img"
            />
          </div>
          <div class="footer__payments-item">
            <img
              src="assets/images/footer-payments/belcard.png"
              alt=""
              class="footer__payments-img"
            />
          </div>
          <div class="footer__payments-item">
            <img
              src="assets/images/footer-payments/master_id_check.png"
              alt=""
              class="footer__payments-img"
            />
          </div>
          <div class="footer__payments-item">
            <img
              src="assets/images/footer-payments/master.png"
              alt=""
              class="footer__payments-img"
            />
          </div>
          <div class="footer__payments-item">
            <img
              src="assets/images/footer-payments/visa.png"
              alt=""
              class="footer__payments-img"
            />
          </div>
          <div class="footer__payments-item">
            <img
              src="assets/images/footer-payments/visa_secure.jpeg"
              alt=""
              class="footer__payments-img"
            />
          </div>
          <div class="footer__payments-item">
            <img
              src="assets/images/footer-payments/unionpay_logo.png"
              alt=""
              class="footer__payments-img"
            />
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="footer__copypaste">
          © 2013 - 2024 Persik.by онлайн-телевидение
        </div>
      </div>
    </div>
  </div>
</div>
