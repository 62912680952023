import { FormControl } from '@angular/forms';

export function confirmPasswordValidator(password: FormControl) {

  return (control: FormControl) => {
    const result: boolean = control.value === password.value;
    if (result) {
      return null;
    } else {
      return {
        confirmPasswordValidator: {
          valid: false
        }
      };
    }
  };

}
