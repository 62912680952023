import { Injectable } from '@angular/core';

@Injectable()
export class TranslitService {

  private readonly latin: string[] =  ['ya', 'yu', 'ch', 'sh', 'sch', 'zh', 'a', 'b', 'v', 'g', 'd', 'e', 'e', 'z', 'i', 'j', 'k', 'l',
  'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f', 'h', 'c', 'y', 'e', '' , ''];
  private readonly cyrill: string[] = ['я', 'ю', 'ч', 'ш',  'щ', 'ж', 'а', 'б', 'в', 'г', 'д', 'е', 'ё', 'з', 'и', 'й', 'к', 'л', 'м',
  'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ы', 'э', 'ь', 'ъ'];

  public convertCyrillToLatin(text: string, id?: any): string {
    if (text) {
      const withoutBrackets: string = text.replace(new RegExp(' [(][a-zA-Z0-9А-Яа-я-\/ ]*[)]', 'gmu'), '');
      let withoutSpace: string = this.replaceSpace(withoutBrackets);
      withoutSpace = withoutSpace.toLowerCase();
      for (let i = 0; i < this.cyrill.length; i++) {
        const reg = new RegExp(this.cyrill[i], 'g');
        withoutSpace = withoutSpace.replace(reg, this.latin[i]);
      }
      if (id) {
        return withoutSpace.concat('-', id.toString());
      }
      return withoutSpace;
    }
    return '';
  }

  private replaceSpace(text: string): string {
    return text.replace(new RegExp(' ', 'g'), '-');
  }

}
