import { AuthService } from './auth.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
var PaymentService = /** @class */ (function () {
    function PaymentService(authService) {
        this.authService = authService;
        this.paymentCompleteEvent = new Subject();
    }
    PaymentService.prototype.setTariff = function (tariff, period, payments) {
        this._tariff = tariff;
        this._period = period;
        this._paymentMethods = payments;
    };
    Object.defineProperty(PaymentService.prototype, "selectedTariff", {
        get: function () {
            return this._tariff;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentService.prototype, "period", {
        get: function () {
            return this._period;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentService.prototype, "paymethods", {
        get: function () {
            return this._paymentMethods;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentService.prototype, "isHavePackage", {
        get: function () {
            if (this._tariff && this._period) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    PaymentService.prototype.setPaymentMethods = function (payments) {
        this._paymentMethods = payments;
        console.log(this._paymentMethods);
    };
    PaymentService.prototype.setPaymentStamp = function () {
        var _this = this;
        var paymentStamp = {
            count: 0,
            time: moment().unix()
        };
        this.authService.getUserSubscriptions().then(function (res) {
            if (res && res.length) {
                paymentStamp.count = res.length;
            }
            localStorage.setItem('paymentStamp', JSON.stringify(paymentStamp));
            _this.startPaymentCheck();
        });
    };
    PaymentService.prototype.clearPaymentStamp = function () {
        localStorage.removeItem('paymentStamp');
    };
    PaymentService.prototype.startPaymentCheck = function () {
        var _this = this;
        if (this.authService.isLogin) {
            clearInterval(this.paymentCheckTimer);
            this.paymentCheckTimer = setInterval(function () {
                _this.checkPayment();
            }, 60000);
        }
    };
    Object.defineProperty(PaymentService.prototype, "paymentStamp", {
        get: function () {
            return JSON.parse(localStorage.getItem('paymentStamp'));
        },
        enumerable: true,
        configurable: true
    });
    PaymentService.prototype.checkPayment = function () {
        var _this = this;
        var paymentStamp = this.paymentStamp;
        if (paymentStamp) {
            var currentUnix_1 = moment().unix();
            if (currentUnix_1 - paymentStamp.time > 3600 * 72) {
                this.authService.getUserSubscriptions().then(function (res) {
                    if (res.length > paymentStamp.count) {
                        _this.paymentCompleteEvent.next(currentUnix_1);
                    }
                    _this.clearPaymentStamp();
                    clearInterval(_this.paymentCheckTimer);
                });
            }
            else {
                this.authService.getUserSubscriptions().then(function (res) {
                    if (res) {
                        if (res.length > paymentStamp.count) {
                            _this.paymentCompleteEvent.next(currentUnix_1);
                            _this.clearPaymentStamp();
                            clearInterval(_this.paymentCheckTimer);
                        }
                    }
                });
            }
        }
        else {
            clearInterval(this.paymentCheckTimer);
        }
    };
    return PaymentService;
}());
export { PaymentService };
