import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()

export class LoadingService {
  public loadingState: Subject<boolean> = new Subject();

  public startLoading(): void {
    this.loadingState.next(true);
  }

  public stopLoading(): void {
    this.loadingState.next(false);
  }
}
