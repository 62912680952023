<div class="complete-modal-wrapper" *ngIf="isShowRegCompleteModal">
  <div class="complete-modal">
    <div class="complete-modal__close" id="close_reg_btn" (click)="hideRegCompleteModal()">
      <img src="assets/images/icons/modal-close-icon.svg" alt="" class="complete-modal__close-img">
    </div>
    <span>Уважаемый пользователь, спасибо за регистрацию и приятного просмотра!</span>
  </div>
</div>

<div class="complete-modal-wrapper" *ngIf="isShowPayCompleteModal">
  <div class="complete-modal">
    <div class="complete-modal__close" id="close_pay_btn" (click)="hidePayCompleteModal()">
      <img src="assets/images/icons/modal-close-icon.svg" alt="" class="complete-modal__close-img">
    </div>
    <span>Уважаемый пользователь, спасибо за оплату и приятного просмотра!</span>
  </div>
</div>
