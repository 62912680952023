import { DataService } from './data.service';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthUser,
  UserInfo,
  UserSubscription,
  Products,
  Payment,
  PaymentInfo,
  FeedbackData,
  CodeData,
  RetailData
} from '../models';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly BASE_URL = 'https://api.persik.by/';
  public loginState: Subject<boolean> = new Subject<boolean>();
  public uuid = '';
  public authHook = new Subject();

  public retailData: RetailData;

  constructor(private http: HttpClient, private dataService: DataService) {
    // window['authHook'] = this.authHook;
  }

  /* setAuthHook(): void {
      this.authHook.subscribe(res => {
        this.getULoginInfo(res.toString());
         this.testUloginPut(res.toString()).then(r => {
          console.log(r);
        });
      });
    } */

  setPhoneCallback(phone: string): Promise<any> {
    const params: HttpParams = new HttpParams().set('phone', phone);
    return this.http
      .post('https://api.persik.by/v2/forms/tariffrequest', params)
      .toPromise();
  }

  /* private getULoginInfo(token: string): Promise<any> {
      const params: HttpParams = new HttpParams().set('token', token).set('action', 'Auth2.connectNetwork');
      return this.http.post('https://persik.by/api.php', params).toPromise();
    } */

  /* private testUloginPut(token: string): Promise<any> {
      const formData: FormData = new FormData();
      formData.append('token', token);
      return this.http.post('v1/account/social', formData).toPromise();
    } */

  checkEmail(email: string): Promise<EmailCheck> {
    if (email) {
      // const uriEmail = this.convertEmailToURI(email);
      const params = new HttpParams()
        .set('email', email);
      return this.http
        .get<EmailCheck>(this.BASE_URL.concat('v2/auth/check?'), { params })
        .toPromise();
    }
  }

  /* public get isElementRetail(): boolean {
    return this.retailData.shop_code ==
  } */

  getUserPlaylist(): Promise<string> {
    return this.http
      .get<{ url: string }>(this.BASE_URL.concat('v2/content/playlisturl'))
      .map(res => res.url)
      .toPromise();
  }

  updateUserPlaylist(): Promise<string> {
    return this.http
      .get<{ url: string }>(
        this.BASE_URL.concat('v2/content/updatePlaylistUrl')
      )
      .map(res => res.url)
      .toPromise();
  }

  /* private convertEmailToURI(email: string): string {
    return email.replace(new RegExp(/\+/, 'g'), ' ');
  } */

  login(email: string, password: string): Promise<AuthUser> {
    const params: HttpParams = new HttpParams()
      .set('email', email)
      .set('password', password);
    return this.http
      .post<AuthUser>(this.BASE_URL.concat('v1/account/login'), {}, { params })
      .toPromise();
  }

  register(email: string, password: string): Promise<any> {
    const params: HttpParams = new HttpParams()
      .set('email', email)
      .set('password', password);
    return this.http
      .post<AuthUser>(
        this.BASE_URL.concat('v1/account/registration'),
        {},
        { params }
      )
      .toPromise();
  }

  private clearChannelList(): void {
    this.dataService.channels = [];
  }

  logout() {
    this.token = '';
    this.user_id = '';
    this.changeLoginState(false);
    this.clearChannelList();
  }

  changeLoginState(isLogin: boolean) {
    this.loginState.next(isLogin);
  }

  createPayment(payment: Payment, cardExpired?: number): Promise<PaymentInfo> {
    let futureUnix: number;
    let monthCount = 6;
    if (payment.product_option_id) {
      if (+payment.product_option_id[0] === 192 || +payment.product_option_id[0] === 193) {
        monthCount = 12;
      }
    }
    // currentUnix + 170 * 24 * 3600; // Костыль для работы рекурентных платежей
    futureUnix = cardExpired ? cardExpired : moment().add(monthCount, 'month').unix();
    const formData: FormData = new FormData();
    formData.append('pay_sys', payment.pay_sys);
    if (payment.litres_item_id) {
      formData.append(
        'litres_item_id[]',
        `${payment.litres_item_id.toString()}`
      );
    } else {
      formData.append(
        'product_option_id[]',
        `${payment.product_option_id.toString()}`
      );
    }
    formData.append('card_date', moment.unix(futureUnix).format('YYYY-MM'));
    return this.http
      .post<PaymentInfo>(this.BASE_URL.concat('v2/billing/payment'), formData)
      .toPromise();
  }

  sendFeedback(data: FeedbackData): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('email', data.email);
    formData.append('subject', data.subject);
    formData.append('message', data.message);
    formData.append('name', data.name);
    formData.append('phone', data.phone);
    return this.http
      .post(this.BASE_URL.concat('v2/forms/contacts'), formData)
      .toPromise();
  }

  sendCode(data: CodeData): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('code', data.code);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    return this.http
      .post(this.BASE_URL.concat('v2/billing/activateCode'), formData)
      .toPromise();
  }

  set token(token: string) {
    if (token.length > 0) {
      localStorage.setItem('user_token', token);
    } else {
      localStorage.removeItem('user_token');
    }
  }

  get token(): string {
    const token = localStorage.getItem('user_token');
    if (token) {
      return token;
    }
    return '';
  }

  set user_id(user_id: string) {
    if (user_id.length > 0) {
      localStorage.setItem('user_id', user_id);
    } else {
      localStorage.removeItem('user_id');
    }
  }

  get user_id(): string {
    const id = localStorage.getItem('user_id');
    if (id) {
      return id;
    }
    return '';
  }

  get isLogin(): boolean {
    return this.token.length > 0 && this.user_id.length > 0;
  }

  get isRetailLogin(): boolean {
    if (this.retailData) {
      return true;
    }
    return false;
  }

  getAccountInfo(): Promise<UserInfo> {
    return this.http
      .get<UserInfo>(this.BASE_URL.concat('v1/account/info'))
      .toPromise();
  }

  forgotPassword(email: string): Promise<any> {
    /* const params: HttpParams = new HttpParams()
        .set('email', email); */
    const formData: FormData = new FormData();
    formData.append('email', email);
    return this.http
      .post<any>(this.BASE_URL.concat('v1/account/forgot'), formData)
      .toPromise();
  }

  setAccountInfo(userInfo: UserInfo): Promise<any> {
    const params: HttpParams = new HttpParams()
      .set('email', userInfo.email)
      .set('pass_code', userInfo.pass_code)
      .set('name', userInfo.name)
      .set('sex', userInfo.sex)
      .set('year', (+userInfo.year / 1000).toString())
      .set('phone', userInfo.phone);
    return this.http
      .post<any>(this.BASE_URL.concat('v1/account/info'), {}, { params })
      .toPromise();
  }

  getUserSubscriptions(): Promise<UserSubscription[]> {
    return this.http
      .get<UserSubscription[]>(this.BASE_URL.concat('v2/billing/subscriptions'))
      .toPromise();
  }

  getTariffs(): Promise<Products> {
    return this.http
      .get<Products>(this.BASE_URL.concat('v2/billing/products'))
      .toPromise();
  }

  getAllTariffs(): Promise<Products> {
    return this.http
      .get<Products>(this.BASE_URL.concat('v2/billing/productsActive'))
      .toPromise();
  }

  retailLogin(name: string, password: string): Promise<RetailData> {
    return new Promise((resolve, reject) => {
      const params: HttpParams = new HttpParams()
      .set('username', name)
      .set('password', password);
      return this.http.post<RetailData>(this.BASE_URL.concat('v2/retail/logon'), {}, {params}).toPromise().then(response => {
        this.retailData = response;
        resolve(response);
      }).catch(err => {
        console.log('Login error: ', err);
        reject();
      });
    });
  }

  retailLogout(): void {
    this.retailData = null;
  }
}

interface EmailCheck {
  name?: string;
  exists: boolean;
}
