import { Genre } from './category';

export class Channel {
    channel_id: number;
    name: string;
    dvr_sec: number;
    age_rating: string;
    genres: number[];
    logo: string;
    priority: number;
    rank: number;
    available: boolean;
    stream_url: string;

    constructor() {
      this.name = 'Нет данных';
      this.logo = '';
    }
}

export class FeatureChannel {
  channel_id: number;
}

export interface Channels {
    channels: Channel[];
    featured_channels: FeatureChannel[];
    categories: Genre[];
}

export interface ChannelsData {
    channels: Channel[];
    featured_channels: FeatureChannel[];
    categories: Genre[];
}

export interface FeaturedChannelsTape {
  title: string;
  channels: Channel[];
}

export interface FeaturedVideosTape {
  title: string;
  videos: any[];
}
