import { Router } from '@angular/router';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(router) {
        this.router = router;
    }
    Object.defineProperty(FooterComponent.prototype, "isDark", {
        get: function () {
            return !this.router.url.includes('private') &&
                !this.router.url.includes('faq') &&
                !this.router.url.includes('paymethods') &&
                !this.router.url.includes('bank-card') &&
                !this.router.url.includes('watch-free') &&
                !this.router.url.includes('speedtest') &&
                !this.router.url.includes('offer');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "isTvGuide", {
        get: function () {
            return this.router.url.includes('tv-guide');
        },
        enumerable: true,
        configurable: true
    });
    FooterComponent.prototype.goTo = function (path, fragment) {
        if (fragment) {
            this.router.navigate([path], { fragment: fragment });
        }
        else {
            this.router.navigate([path]);
        }
    };
    return FooterComponent;
}());
export { FooterComponent };
