import { MediaStateData } from './models/mediaStateData';
import { MediaState } from './models/mediaState';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  state: MediaState = [];

  private readonly localStorageKey: string = 'mediaState';
  private readonly stateSize: number = 20;

  constructor() {
    this.load();
  }

  load(): void {
    this.state = this.loadState();
  }


  set(payload: MediaStateData): void {
    const existIndex = this.state.findIndex((i: MediaStateData) => i.id === payload.id);

    if (existIndex !== -1) {
      this.state.splice(existIndex, 1);
    }
    this.state.unshift(payload);
    if (this.state.length > this.stateSize) {
      this.state.slice(0, this.stateSize);
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(this.state));
  }

  getData(id: string | number): MediaStateData {
    return this.state.find((i: MediaStateData) => i.id === id);
  }

  getRecently(): MediaStateData[] {
    return this.state.filter((i: MediaStateData) => i.state === 'inProgress');
  }

  getFinished(): MediaStateData[] {
    return this.state.filter((i: MediaStateData) => i.state === 'finished');
  }

  getViewedChannels(): MediaStateData[] {
    return this.state.filter((i: MediaStateData) => i.state === 'channel');
  }

  private loadState(): MediaState {
    const state = JSON.parse(localStorage.getItem(this.localStorageKey));
    return state ? state : [];
  }
}
