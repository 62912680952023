import { Component, OnInit, OnDestroy } from '@angular/core';
import { AnalyticService, ModalController } from '../../services';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { ModalName } from '../../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ya-metrika-modal',
  templateUrl: './ya-metrika.component.html',
  styleUrls: ['./ya-metrika.component.scss']
})

export class YaMetrikaModalComponent implements OnInit, OnDestroy {

  public isShowRegCompleteModal: boolean;
  public isShowPayCompleteModal: boolean;
  private modalControllerSubscribtion: Subscription;

  constructor(
    private analyticService: AnalyticService,
    private ym: NgxMetrikaService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.ym.hit.emit();
    this.modalControllerSubscribtion = this.modalCtrl.complete_modal.subscribe(res => {
      if (res) {
        if (res === ModalName.REG_COMPLETE) {
          this.isShowRegCompleteModal = true;
          localStorage.setItem('regApply', 'true');
        }
        if (res === ModalName.PAY_COMPLETE) {
          this.isShowPayCompleteModal = true;
        }
      } else {
        this.isShowRegCompleteModal = false;
        this.isShowPayCompleteModal = false;
      }
    });
  }

  public hideRegCompleteModal(): void {
    this.analyticService.targetSend('spasibo-za-registr', 47757748);
    this.modalCtrl.complete_modal.next();
    localStorage.removeItem('regApply');
  }

  public hidePayCompleteModal(): void {
    this.analyticService.targetSend('spasibo-za-oplaty', 47757844);
    this.modalCtrl.complete_modal.next();
  }

  ngOnDestroy() {
    if (this.modalControllerSubscribtion) {
      this.modalControllerSubscribtion.unsubscribe();
    }
  }
}

