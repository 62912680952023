export type ModalType = 'login' | 'complete' | 'error' | 'registration' | 'forgot' |
  'reg_complete' | 'pay_complete' | 'vendor_send' | 'fast_auth' | 'test_request' | 'istock_complete';

export namespace ModalName {
  export const LOGIN = 'login';
  export const INFO = 'info';
  export const REGISTER = 'registration';
  export const FORGOT = 'forgot';
  export const REG_COMPLETE = 'reg_complete';
  export const PAY_COMPLETE = 'pay_complete';
  export const SEND_COMPLETE = 'complete';
  export const SEND_ERROR = 'error';
  export const VENDOR_SEND = 'vendor_send';
  export const FAST_AUTH = 'fast_auth';
  export const TEST_REQUEST = 'test_request';
  export const ISTOCK_COMPLETE = 'istock_complete';
}
