<div class="login" (click)="stopProp($event)">

  <!-- <div class="enter-selector">
    <button (click)="doAuthorization()" >Авторизация</button>

  </div> -->

  <div class="modal-close" (click)="closeModal()">
    <img src="assets/images/icons/modal-close-icon.svg" alt="" class="modal-close__img">
  </div>

  <!-- Форма авторизации -->

  <form [hidden]="!isAuthorization" class="form" novalidate [formGroup]="authorizationForm"
    (ngSubmit)="onLoginSubmit()">
    <div class="modal-title">Вход</div>

    <div *ngIf="isWrongParams">Неверный логин или пароль</div>

    <div class=form-group [class.form-group_dirty]="email.value">
      <input id="email" type="text" (keydown)="clearValidate(email)" [class.has-error]="email.errors || isWrongParams"
        class="form-group__input" formControlName="email">
      <label for="email" class="form-group__label">Email:</label>
      <div class="field-error" *ngIf="email.errors && email.touched">
        <span *ngIf="email.errors['required']" class="form-group__error">Поле обязательное для заполнения</span>
        <span *ngIf="email.errors['emailValidator'] && !email.errors.required" class="form-group__error">Неверный
          формат</span>
        <span *ngIf="email.errors['emailExistValidator']" class="form-group__error">Пользователь с таким email не
          зарегистрирован</span>
      </div>
    </div>

    <div class=form-group [class.form-group_dirty]="password.value">
      <input id="password" type="password" [class.has-error]="password.errors || isWrongParams"
        class="form-group__input" formControlName="password">
      <label for="password" class="form-group__label">Пароль:</label>
      <div class="field-error" *ngIf="password.errors && (password.dirty || password.touched)">
        <span *ngIf="password.errors['required']" class="form-group__error">Поле обязательное для заполнения</span>
        <span *ngIf="password.errors['minlength']" class="form-group__error">Минимальная длина пароля
          {{ password.errors['minlength'].requiredLength }}</span>
      </div>
    </div>
    <div class="modal-btns">
      <input type="submit" [disabled]="!authorizationForm.valid" class="btn-orange" value="Войти">
      <span (click)="doRegistartion()" class="modal-btns__link">Регистрация</span>
      <span (click)="doForgot($event)" class="modal-btns__link modal-btns__link_forgot">Забыл пароль</span>
    </div>

  </form>


  <!-- Форма регистрации -->

  <form [hidden]="isAuthorization" class="form" novalidate [formGroup]="registrationForm"
    (ngSubmit)="onRegistrationSubmit()">
    <div class="modal-title">Регистрация</div>
    <div class=form-group [class.form-group_dirty]="r_email.value">
      <input id="r_email" (keydown)="clearValidate(r_email)" type="text" [class.has-error]="r_email.errors"
        class="form-group__input" formControlName="r_email">
      <label for="r_email" class="form-group__label">Email:</label>

      <div class="field-error" *ngIf="r_email.errors && r_email.touched">
        <span *ngIf="r_email.errors['required']" class="form-group__error">Поле обязательное для заполнения</span>
        <span *ngIf="r_email.errors['emailValidator'] && !r_email.errors.required" class="form-group__error">Неверный
          формат</span>
        <span *ngIf="r_email.errors['emailNotExistValidator']" class="form-group__error">Пользователь с таким email уже
          зарегистрирован</span>
      </div>
    </div>

    <div class=form-group [class.form-group_dirty]="r_password.value">
      <input id="r_password" type="password" [class.has-error]="r_password.errors" class="form-group__input"
        formControlName="r_password">
      <label for="r_password" class="form-group__label">Пароль:</label>

      <div class="field-error" *ngIf="r_password.errors && (r_password.dirty || r_password.touched)">
        <span *ngIf="r_password.errors['required']" class="form-group__error">Поле обязательное для заполнения</span>
        <span *ngIf="r_password.errors['minlength']" class="form-group__error">Минимальная длина пароля
          {{ r_password.errors['minlength'].requiredLength }}</span>
      </div>
    </div>

    <div class=form-group [class.form-group_dirty]="confirm_password.value">

      <input id="confirm_password" type="password" [class.has-error]="confirm_password.errors" class="form-group__input"
        formControlName="confirm_password">
      <label for="confirm_password" class="form-group__label">Повторите пароль:</label>
      <div class="field-error" *ngIf="confirm_password.errors && (confirm_password.dirty || confirm_password.touched)">
        <span *ngIf="confirm_password.errors['required']" class="form-group__error">Поле обязательное для
          заполнения</span>
        <span *ngIf="confirm_password.errors['confirmPasswordValidator']" class="form-group__error">Пароли не
          совпадают</span>
      </div>
    </div>

    <div class="modal-btns">
      <input type="submit" [disabled]="!registrationForm.valid" class="btn-orange" value="Зарегистрироваться">
      <span (click)="doAuthorization()" class="modal-btns__link">Войти</span>
    </div>

    <div class="modal-copyright">
      Нажимая на кнопку «Зарегистрироваться» вы принимаете условия <a routerLink="offer" (click)="closeModal()">Пользовательского соглашения</a>
    </div>
  </form>

</div>