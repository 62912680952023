import { ModalName } from '../models/modal';
import { ModalController, AuthService } from '../services';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private modalCtrl: ModalController) {}

  canActivate() {
    const isLogin: boolean = this.authService.isLogin;
    if (isLogin) {
      return true;
    } else {
      this.router.navigate(['']);
      this.modalCtrl.present(ModalName.LOGIN);
    }
  }
}
