import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import * as i0 from "@angular/core";
var TimeService = /** @class */ (function () {
    function TimeService(platform) {
        var _this = this;
        this.platform = platform;
        this.interval = 60000;
        this.fastInterval = 1000;
        this.timeController = new Subject();
        this.timeControllerFast = new Subject();
        if (isPlatformBrowser(this.platform)) {
            setInterval(function () {
                _this.timeController.next(_this.currentTime);
            }, this.interval);
            setInterval(function () {
                _this.timeControllerFast.next(_this.currentTime);
            }, this.fastInterval);
        }
    }
    Object.defineProperty(TimeService.prototype, "currentTime", {
        get: function () {
            return moment().unix();
        },
        enumerable: true,
        configurable: true
    });
    TimeService.prototype.getDate = function () {
        return moment().format('YYYY-MM-DD');
    };
    TimeService.prototype.getPreviousDate = function () {
        return moment().add(-1, 'days').format('YYYY-MM-DD');
    };
    TimeService.prototype.getStartOfToday = function () {
        return moment().startOf('day').fromNow();
    };
    TimeService.prototype.getNextDate = function () {
        return moment().add(+1, 'days').format('YYYY-MM-DD');
    };
    TimeService.prototype.getThreeDaysAgoDate = function () {
        return moment().add(-3, 'days').format('YYYY-MM-DD');
    };
    TimeService.prototype.getArchiveDate = function () {
        return moment().add(-24, 'days').format('YYYY-MM-DD');
    };
    TimeService.prototype.convertToTime = function (time) {
        if (time) {
            return moment.unix(time).format('HH:mm');
        }
        return '';
    };
    TimeService.prototype.convertToDate = function (date) {
        if (date.length > 10) {
            return moment('YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
        }
        return '';
    };
    TimeService.prototype.getDurationFromTime = function (time) {
        if (time) {
            var hours = Math.floor(time / 3600);
            var minutes = Math.floor((time - hours * 3600) / 60);
            var seconds = Math.floor(time - hours * 3600 - minutes * 60);
            return this.checkZero(hours) + ":" + this.checkZero(minutes) + ":" + this.checkZero(seconds);
        }
        return '';
    };
    TimeService.prototype.getCurrentTime = function () {
        return moment().unix();
    };
    TimeService.prototype.checkZero = function (time) {
        return "" + (time > 9 ? time : '0' + time);
    };
    TimeService.ngInjectableDef = i0.defineInjectable({ factory: function TimeService_Factory() { return new TimeService(i0.inject(i0.PLATFORM_ID)); }, token: TimeService, providedIn: "root" });
    return TimeService;
}());
export { TimeService };
