
<div class="preloader-wrapper">
  <div class="preloader">
    <div class="bubbling">
      <div class="bubbling__item" id="bubblingG_1">
      </div>
      <div class="bubbling__item" id="bubblingG_2">
      </div>
      <div class="bubbling__item" id="bubblingG_3">
      </div>
    </div>
  </div>
</div>
