import { DataService } from './../../../services/data.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService, ModalController } from './../../../services';
import {
  emailCorrectValidator,
  confirmPasswordValidator,
  emailExistValidator,
  emailNotExistValidator
} from '../../../validators';
import { ModalName } from '../../../models/modal';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})

export class ModalLoginComponent implements OnInit {

  public authorizationForm: FormGroup;
  public email: FormControl;
  public password: FormControl;
  @Input() isAuthorization = true;

  public registrationForm: FormGroup;
  public r_email: FormControl;
  public r_password: FormControl;
  public confirm_password: FormControl;

  public isWrongParams = false;

  constructor(private authService: AuthService, private modalCtrl: ModalController, private dataService: DataService) {}

  ngOnInit(): void {
    this.createFormControls();
    this.createForms();
  }

  public closeModal(): void {
    this.modalCtrl.destroy();
  }

  public stopProp(event: Event): void {
    if (event) {
      event.stopPropagation();
    }
  }

  private createForms(): void {
    this.authorizationForm = new FormGroup({
      email: this.email,
      password: this.password
    });

    this.registrationForm = new FormGroup({
      r_email: this.r_email,
      r_password: this.r_password,
      confirm_password: this.confirm_password
    });
  }

  public onLoginSubmit(): void {
    if (this.authorizationForm.valid) {
      this.login(this.email.value, this.password.value);
    }
  }

  public doForgot(event: MouseEvent): void {
    event.stopPropagation();
    this.modalCtrl.present(ModalName.FORGOT);
  }

  public onRegistrationSubmit(): void {
    if (this.registrationForm.valid) {
      this.authService.register(this.r_email.value, this.r_password.value).then((v) => {
        this.login(this.r_email.value, this.r_password.value);
        this.modalCtrl.destroy();
        this.modalCtrl.complete_modal.next(ModalName.REG_COMPLETE);
      }).catch(e => {
        console.log('error: ', e);
      });
    }
  }

  public doAuthorization(): void {
    this.isAuthorization = true;
  }

  public doRegistartion(): void {
    this.isAuthorization = false;
  }

  public clearValidate(control: FormControl): void {
    control.markAsUntouched();
  }

  private login(email: string, password: string): void {
    this.authService.login(email, password).then(res => {
      if (res.auth_token.length > 0) {
        this.authService.token = res.auth_token;
        this.authService.user_id = res.user_id;
        this.modalCtrl.destroy();
        this.authService.changeLoginState(true);
        this.isWrongParams = false;

        this.dataService.channels = [];
        this.dataService.getChannels().then();
      } else {
        this.isWrongParams = true;
      }
    }).catch(() => {
      this.isWrongParams = true;
    });
  }

  private createFormControls(): void {
    this.email = new FormControl('', {
      validators: [Validators.required, emailCorrectValidator],
      asyncValidators: [emailExistValidator(this.authService)],
      updateOn: 'blur'
    });
    this.password = new FormControl('', [Validators.required, Validators.minLength(6)]);

    this.r_email = new FormControl('', {
      validators: [Validators.required, emailCorrectValidator],
      asyncValidators: [emailNotExistValidator(this.authService)],
      updateOn: 'blur'
    });
    this.r_password = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.confirm_password = new FormControl('', [Validators.required, confirmPasswordValidator(this.r_password)]);
  }

}

