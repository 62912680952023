export namespace PlayerEvents {
    export const PLAYER_READY = 'PLAYER_READY';
    export const PLAYER_PLAY = 'PLAYER_PLAY';
    export const PLAYER_PAUSE = 'PLAYER_PAUSE';
    export const PLAYER_ERROR_SUBSCRIPTION = 'PLAYER_ERROR_SUBSCRIPTION';
    export const PLAYER_ERROR_LOGIN = 'PLAYER_ERROR_LOGIN';
}

export namespace ControlEvents {
    export const CONTROL_PLAY = 'CONTROL_PLAY';
    export const CONTROL_PAUSE = 'CONTROL_PAUSE';
    export const CONTROL_SEEK = 'CONTROL_SEEK';
    export const CONTROL_VOLUME = 'CONTROL_VOLUME';
}

export class VideoControlEventInfo {
    name: string;
    value: any;

    constructor(name: string, value?: any) {
        this.name = name;
        this.value = value;
    }
}
