import { Video, ContentType } from './vod';

export interface FavoriteChannel {
  channel_id: number;
  added_time: number;
}

export interface FavoriteTvshow {
  tvshow_id: string;
  added_time: number;
}

export interface FavoriteVideo {
  video_id: number;
  added_time: number;
}

export interface FavoriteBook {
  litres_item_id: number;
  added_time: number;
}

export interface FavoritesData {
  channels: FavoriteChannel[];
  videos: FavoriteVideo[];
  tvshows: FavoriteTvshow[];
  litres: FavoriteBook[];
}

export class FavoriteSettings {
  id: number | string;
  type: ContentType;
  info?: Video;
  genres?: string[];

  constructor(id: number | string, type: ContentType, info?: Video, genres?: string[]) {
      this.id = id;
      this.type = type;
      this.info = info;
      this.genres = genres;
  }
}
