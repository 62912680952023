import { Subscription } from 'rxjs';
import { Genre, Video, VideoControlEventInfo, PlayerEvents, ControlEvents, ContentName } from './../../models';
import { DataService } from './../../services';
import { Location, isPlatformBrowser } from '@angular/common';
import { PlayerComponent } from './../../components/player/player.component';
import { Component, OnInit, ViewChild, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})

export class VideoPlayerComponent implements OnInit, OnDestroy {

  public isPlaying: boolean;
  public currentTime: number;
  public duration: number;
  public genres: string[] = [];
  public actorNames: string[] = [];
  private isCanPlay: boolean;
  private video_id: string;
  private playerTimeController: any;
  public videoInformation: Video;
  private showControlsTimer: any;
  public isShowControls = true;
  @ViewChild('player') player: PlayerComponent;
  public isBrowser;

  private playerEventsSubscriber: Subscription;
  private activatedRouteSubscriber: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platform
  ) {}

  ngOnInit(): void {
    this.activatedRouteSubscriber = this.activatedRoute.params.subscribe(response => {
      const urlSplit: string[] = response['id'].split('-');
      this.video_id = urlSplit[urlSplit.length - 1];
      if (isPlatformBrowser(this.platform)) {
        this.isBrowser = true;
        setTimeout(() => {
          this.initializeVideo();
          this.initPlayerBehaviors();
        }, 100);
      }
      this.autoHideControls();
    });
  }

  public onVideoControllerEvents(event: VideoControlEventInfo): void {
    switch (event.name) {
      case ControlEvents.CONTROL_PAUSE:
        this.player.pause();
        break;
      case ControlEvents.CONTROL_PLAY:
        this.player.resume();
        break;
      case ControlEvents.CONTROL_SEEK:
        this.player.seek(event.value);
        break;
      case ControlEvents.CONTROL_VOLUME:
        this.player.volume = event.value / 100;
        break;
      default:
        break;
    }
  }

  public goBack(): void {
    this.location.back();
  }

  public autoHideControls(): void {
    clearTimeout(this.showControlsTimer);
    this.isShowControls = true;
    this.showControlsTimer = setTimeout(() => {
      this.isShowControls = false;
    }, 3000);
  }

  public get volume(): number {
    if (this.player) {
      return this.player.volume;
    }
    return 0;
  }

  private initializeVideo(): void {
    if (this.video_id) {
      if (this.video_id.length > 10) {
        this.dataService.getTvshowInfo(this.video_id).then(res => {
          this.dataService.getVideoInfo(+res.video_id).then(video => {
            this.videoInformation = video;
            this.loadGenres();
            this.loadPersons();
          });
        });
        this.playTvshow();
      } else {
        this.dataService.getVideoInfo(+this.video_id).then(res => {
          this.videoInformation = res;
          this.loadGenres();
          this.loadPersons();
        });
        this.playVideo();
      }
    }
  }

  private loadGenres(): void { // Получение списка жанров для видео по id жанров
    this.dataService.loadVodCategories().then(categories => {
      const allGenresArray = categories.map(cat => cat.genres);
      const allGenres: Genre[] = [];
      allGenresArray.forEach(genre => {
        allGenres.push(...genre);
      });

      this.genres = allGenres.filter(genre => {
        return this.videoInformation.genres.some(genreId => genreId === genre.id);
      }).map(res => {
        const name = res.name;
        const part = name.split(')');
        if (part[1]) {
          return part[1];
        }
        return name;
      });
    });
  }

  private loadPersons(): void {
    if (this.videoInformation.cast && this.videoInformation.cast.length > 0) {
      this.dataService.loadActors(this.videoInformation.cast).then(res => {
        this.actorNames = res.map(actor => actor.name);
      });
    }
  }

  private playVideo(): void {
    if (this.player) {
      this.player.play(this.video_id, ContentName.VIDEO);
    }
  }

  private playTvshow(): void {
    if (this.player) {
      this.player.play(this.video_id, ContentName.TV);
    }
  }

  private initPlayerBehaviors() {                          // Подписка на события плеера
    this.playerEventsSubscriber = this.player.playerEvents.subscribe((event) => {
      switch (event) {
        case PlayerEvents.PLAYER_READY:
          this.isCanPlay = true;
          this.isPlaying = true;
          this.duration = this.player.duration;
          break;
        case PlayerEvents.PLAYER_PAUSE:
          this.isPlaying = false;
          this.stopPlayerTimeController();
          break;
        case PlayerEvents.PLAYER_PLAY:
          this.isPlaying = true;
          this.startPlayerTimeController();
          break;
        case PlayerEvents.PLAYER_ERROR_SUBSCRIPTION:
          this.isCanPlay = false;
          break;
        case PlayerEvents.PLAYER_ERROR_LOGIN:
          this.isCanPlay = false;
          break;
        default:
          break;
      }
    });
  }

  private startPlayerTimeController(): void {
    this.playerTimeController = setInterval(() => {
      this.currentTime = this.player.currentTime;
    }, 1000);
  }

  private stopPlayerTimeController(): void {
    clearTimeout(this.playerTimeController);
  }

  ngOnDestroy() {
    if (this.playerTimeController) {
      this.stopPlayerTimeController();
    }
    if (this.playerEventsSubscriber) {
      this.playerEventsSubscriber.unsubscribe();
    }
    if (this.activatedRouteSubscriber) {
      this.activatedRouteSubscriber.unsubscribe();
    }
  }

}
