import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '../../services/modal.controller';
import { AuthService, MetaService } from '../../services';
import { ModalName } from '../../models/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-iptv-component',
  templateUrl: './iptv.component.html',
  styleUrls: ['./iptv.component.scss']
})
export class IptvPageComponent implements OnInit, OnDestroy {
  public isLogin: boolean;
  public isHaveSubscription: boolean;
  private loginStateSubscribe: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalController: ModalController,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.metaService.setDefaultMeta('iptv');
    this.isLogin = this.authService.isLogin;
    this.loadUserSubscriptions();
    this.loginStateSubscribe = this.authService.loginState.subscribe(() => {
      this.isLogin = this.authService.isLogin;
      this.loadUserSubscriptions();
    });
  }

  public goTo(path: string, fragment: string): void {
    this.router.navigate([path], { fragment });
  }

  public try(): void {
    this.modalController.present(ModalName.REGISTER);
  }

  public openTariffs(): void {
    this.router.navigate(['private/tv-tarify']);
  }

  public showOnline(): void {
    this.router.navigate(['']);
  }

  private loadUserSubscriptions(): void {
    if (this.isLogin) {
      this.authService.getUserSubscriptions().then(res => {
        this.isHaveSubscription = res.length > 0;
      });
    }
  }

  ngOnDestroy() {
    if (this.loginStateSubscribe) {
      this.loginStateSubscribe.unsubscribe();
    }
  }
}
