import { Injectable, Inject } from '@angular/core';
import { Meta, Title, MetaDefinition, DOCUMENT } from '@angular/platform-browser';
import { MetaData } from '../models/meta';

@Injectable()

export class MetaService {
  constructor(private meta: Meta, private title: Title, @Inject(DOCUMENT) private dom) {}

  public updateMeta(data: MetaData): void {
    if (data.title && data.title.length > 0) {
      this.title.setTitle(data.title);
    }
    if (data.tags && data.tags.length > 0) {
      for (let i = 0; i < data.tags.length; i++) {
        this.updateTag(data.tags[i]);
      }
    }
  }

  public setDefaultMeta(content: string): void {
    this.title.setTitle(content);
    this.updateTag({
      name: 'keywords',
      content
    });
    this.meta.updateTag({
      name: 'description',
      content
    });
  }

  public createCanonicalURL() {
    const item = document.querySelector('link[rel=canonical]');
    if (item) {
      this.dom.head.removeChild(item);
    }
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    const url = this.dom.URL.split('?')[0];
    link.setAttribute('href', url);
 }

  private updateTag(tag: MetaDefinition): void {
    this.meta.updateTag(tag, `name=${tag.name}`);
  }
}
