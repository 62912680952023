/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ya-metrika.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ya-metrika.component";
import * as i4 from "../../services/analytic.service";
import * as i5 from "@kolkov/ngx-metrika";
import * as i6 from "../../services/modal.controller";
var styles_YaMetrikaModalComponent = [i0.styles];
var RenderType_YaMetrikaModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_YaMetrikaModalComponent, data: {} });
export { RenderType_YaMetrikaModalComponent as RenderType_YaMetrikaModalComponent };
function View_YaMetrikaModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "complete-modal-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "complete-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "complete-modal__close"], ["id", "close_reg_btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideRegCompleteModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "complete-modal__close-img"], ["src", "assets/images/icons/modal-close-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0423\u0432\u0430\u0436\u0430\u0435\u043C\u044B\u0439 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C, \u0441\u043F\u0430\u0441\u0438\u0431\u043E \u0437\u0430 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044E \u0438 \u043F\u0440\u0438\u044F\u0442\u043D\u043E\u0433\u043E \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u0430!"]))], null, null); }
function View_YaMetrikaModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "complete-modal-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "complete-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "complete-modal__close"], ["id", "close_pay_btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hidePayCompleteModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "complete-modal__close-img"], ["src", "assets/images/icons/modal-close-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0423\u0432\u0430\u0436\u0430\u0435\u043C\u044B\u0439 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C, \u0441\u043F\u0430\u0441\u0438\u0431\u043E \u0437\u0430 \u043E\u043F\u043B\u0430\u0442\u0443 \u0438 \u043F\u0440\u0438\u044F\u0442\u043D\u043E\u0433\u043E \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u0430!"]))], null, null); }
export function View_YaMetrikaModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_YaMetrikaModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_YaMetrikaModalComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isShowRegCompleteModal; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isShowPayCompleteModal; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_YaMetrikaModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ya-metrika-modal", [], null, null, null, View_YaMetrikaModalComponent_0, RenderType_YaMetrikaModalComponent)), i1.ɵdid(1, 245760, null, 0, i3.YaMetrikaModalComponent, [i4.AnalyticService, i5.NgxMetrikaService, i6.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var YaMetrikaModalComponentNgFactory = i1.ɵccf("app-ya-metrika-modal", i3.YaMetrikaModalComponent, View_YaMetrikaModalComponent_Host_0, {}, {}, []);
export { YaMetrikaModalComponentNgFactory as YaMetrikaModalComponentNgFactory };
