import { MetaDefinition } from '@angular/platform-browser';
import { MetaData } from '../../models/meta';


export function setContentName(content_name: string): MetaData {
  const tags: MetaDefinition[] = [
    {
      name: 'keywords',
      content: `смотреть тв канал ${content_name} онлайн`
    },
    {
      name: 'description',
      content: `Смотреть tv канал ${content_name} online – прямые трансляции, прямой эфир.
      ✅ Первый месяц всего за 0,30 руб! ✅ Архив 24 дня, Full HD качество, 100 каналов, мультирум.
      info@persik.by
    </div>`
    }
  ];

  const title = `Смотреть тв канал ${content_name} онлайн в прямом эфире`;

  return {
    title,
    tags
  };
}
