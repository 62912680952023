import { ModalName } from '../../models/modal';
import { OnDestroy } from '@angular/core';
import { ModalController } from '../../services/modal.controller';
var ModalWrapperComponent = /** @class */ (function () {
    function ModalWrapperComponent(modalCtrl) {
        var _this = this;
        this.modalCtrl = modalCtrl;
        this.complMessage = 'Запрос отправлен успешно';
        this.testRequestMessage = "<b>\u0421\u043F\u0430\u0441\u0438\u0431\u043E!</b> <br>\n  \u0412 \u0442\u0435\u0447\u0435\u043D\u0438\u0435 \u0440\u0430\u0431\u043E\u0447\u0435\u0433\u043E \u0434\u043D\u044F (\u0435\u0436\u0435\u0434\u043D\u0435\u0432\u043D\u043E \u0441 15 \u0434\u043E 21) \u0432\u044B\u0448\u043B\u0435\u043C \u043D\u0430 \u0442\u0435\u043B\u0435\u0444\u043E\u043D \u043B\u043E\u0433\u0438\u043D \u0438 \u043F\u0430\u0440\u043E\u043B\u044C \u0434\u043B\u044F \u0442\u0435\u0441\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F \u0441\u0435\u0440\u0432\u0438\u0441\u0430.\n  info@persik.by";
        this.errorMessage = 'Ошибка при отправке. Попробуйте позже';
        this.istockMesage = "<b style=\"font-weight: bold\">\u0421\u043F\u0430\u0441\u0438\u0431\u043E \u0437\u0430 \u0437\u0430\u044F\u0432\u043A\u0443!</b><br><br>\n  \u0421\u0447\u0451\u0442 \u043D\u0430 \u043E\u043F\u043B\u0430\u0442\u0443 \u043F\u043E \u0412\u0430\u0448\u0435\u0439 \u0446\u0435\u043D\u0435 \u0432\u044B\u0448\u043B\u0435\u043C \u043D\u0430 \u043F\u043E\u0447\u0442\u0443 \u0432 \u0442\u0435\u0447\u0435\u043D\u0438\u0435 \u0434\u043D\u044F.<br>\n  info@persik.by";
        this.modalControllerSubscription = this.modalCtrl.modal.subscribe(function (res) {
            if (res) {
                _this.isShowModal = true;
                _this.type = res;
            }
            else {
                _this.closeModal();
            }
        });
    }
    ModalWrapperComponent.prototype.closeModal = function () {
        this.isShowModal = false;
        this.type = null;
    };
    Object.defineProperty(ModalWrapperComponent.prototype, "isLogin", {
        get: function () {
            return this.type === ModalName.LOGIN;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalWrapperComponent.prototype, "isRegistration", {
        get: function () {
            return this.type === ModalName.REGISTER;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalWrapperComponent.prototype, "isSendComplete", {
        get: function () {
            return this.type === ModalName.SEND_COMPLETE || this.type === ModalName.TEST_REQUEST;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalWrapperComponent.prototype, "completeMessage", {
        get: function () {
            return this.type === ModalName.TEST_REQUEST ? this.testRequestMessage : this.complMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalWrapperComponent.prototype, "isSendError", {
        get: function () {
            return this.type === ModalName.SEND_ERROR;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalWrapperComponent.prototype, "isForgot", {
        get: function () {
            return this.type === ModalName.FORGOT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalWrapperComponent.prototype, "isStockComplete", {
        get: function () {
            return this.type === ModalName.ISTOCK_COMPLETE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalWrapperComponent.prototype, "isVendorSend", {
        get: function () {
            return this.type === ModalName.VENDOR_SEND;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalWrapperComponent.prototype, "isFastAuth", {
        get: function () {
            return this.type === ModalName.FAST_AUTH;
        },
        enumerable: true,
        configurable: true
    });
    ModalWrapperComponent.prototype.ngOnDestroy = function () {
        if (this.modalControllerSubscription) {
            this.modalControllerSubscription.unsubscribe();
        }
    };
    return ModalWrapperComponent;
}());
export { ModalWrapperComponent };
