/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-info.component";
import * as i3 from "../../../services/modal.controller";
var styles_ModalInfoComponent = [i0.styles];
var RenderType_ModalInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalInfoComponent, data: {} });
export { RenderType_ModalInfoComponent as RenderType_ModalInfoComponent };
export function View_ModalInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "info-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "info-modal__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-btns modal-btns_center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn-orange"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041E\u041A"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
export function View_ModalInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-info", [], null, null, null, View_ModalInfoComponent_0, RenderType_ModalInfoComponent)), i1.ɵdid(1, 49152, null, 0, i2.ModalInfoComponent, [i3.ModalController], null, null)], null, null); }
var ModalInfoComponentNgFactory = i1.ɵccf("app-modal-info", i2.ModalInfoComponent, View_ModalInfoComponent_Host_0, { message: "message" }, {}, []);
export { ModalInfoComponentNgFactory as ModalInfoComponentNgFactory };
