import { PlayerEvents, ControlEvents, ContentName } from './../../models';
import { DataService } from './../../services';
import { Location, isPlatformBrowser } from '@angular/common';
import { PlayerComponent } from './../../components/player/player.component';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var VideoPlayerComponent = /** @class */ (function () {
    function VideoPlayerComponent(activatedRoute, location, dataService, platform) {
        this.activatedRoute = activatedRoute;
        this.location = location;
        this.dataService = dataService;
        this.platform = platform;
        this.genres = [];
        this.actorNames = [];
        this.isShowControls = true;
    }
    VideoPlayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRouteSubscriber = this.activatedRoute.params.subscribe(function (response) {
            var urlSplit = response['id'].split('-');
            _this.video_id = urlSplit[urlSplit.length - 1];
            if (isPlatformBrowser(_this.platform)) {
                _this.isBrowser = true;
                setTimeout(function () {
                    _this.initializeVideo();
                    _this.initPlayerBehaviors();
                }, 100);
            }
            _this.autoHideControls();
        });
    };
    VideoPlayerComponent.prototype.onVideoControllerEvents = function (event) {
        switch (event.name) {
            case ControlEvents.CONTROL_PAUSE:
                this.player.pause();
                break;
            case ControlEvents.CONTROL_PLAY:
                this.player.resume();
                break;
            case ControlEvents.CONTROL_SEEK:
                this.player.seek(event.value);
                break;
            case ControlEvents.CONTROL_VOLUME:
                this.player.volume = event.value / 100;
                break;
            default:
                break;
        }
    };
    VideoPlayerComponent.prototype.goBack = function () {
        this.location.back();
    };
    VideoPlayerComponent.prototype.autoHideControls = function () {
        var _this = this;
        clearTimeout(this.showControlsTimer);
        this.isShowControls = true;
        this.showControlsTimer = setTimeout(function () {
            _this.isShowControls = false;
        }, 3000);
    };
    Object.defineProperty(VideoPlayerComponent.prototype, "volume", {
        get: function () {
            if (this.player) {
                return this.player.volume;
            }
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    VideoPlayerComponent.prototype.initializeVideo = function () {
        var _this = this;
        if (this.video_id) {
            if (this.video_id.length > 10) {
                this.dataService.getTvshowInfo(this.video_id).then(function (res) {
                    _this.dataService.getVideoInfo(+res.video_id).then(function (video) {
                        _this.videoInformation = video;
                        _this.loadGenres();
                        _this.loadPersons();
                    });
                });
                this.playTvshow();
            }
            else {
                this.dataService.getVideoInfo(+this.video_id).then(function (res) {
                    _this.videoInformation = res;
                    _this.loadGenres();
                    _this.loadPersons();
                });
                this.playVideo();
            }
        }
    };
    VideoPlayerComponent.prototype.loadGenres = function () {
        var _this = this;
        this.dataService.loadVodCategories().then(function (categories) {
            var allGenresArray = categories.map(function (cat) { return cat.genres; });
            var allGenres = [];
            allGenresArray.forEach(function (genre) {
                allGenres.push.apply(allGenres, genre);
            });
            _this.genres = allGenres.filter(function (genre) {
                return _this.videoInformation.genres.some(function (genreId) { return genreId === genre.id; });
            }).map(function (res) {
                var name = res.name;
                var part = name.split(')');
                if (part[1]) {
                    return part[1];
                }
                return name;
            });
        });
    };
    VideoPlayerComponent.prototype.loadPersons = function () {
        var _this = this;
        if (this.videoInformation.cast && this.videoInformation.cast.length > 0) {
            this.dataService.loadActors(this.videoInformation.cast).then(function (res) {
                _this.actorNames = res.map(function (actor) { return actor.name; });
            });
        }
    };
    VideoPlayerComponent.prototype.playVideo = function () {
        if (this.player) {
            this.player.play(this.video_id, ContentName.VIDEO);
        }
    };
    VideoPlayerComponent.prototype.playTvshow = function () {
        if (this.player) {
            this.player.play(this.video_id, ContentName.TV);
        }
    };
    VideoPlayerComponent.prototype.initPlayerBehaviors = function () {
        var _this = this;
        this.playerEventsSubscriber = this.player.playerEvents.subscribe(function (event) {
            switch (event) {
                case PlayerEvents.PLAYER_READY:
                    _this.isCanPlay = true;
                    _this.isPlaying = true;
                    _this.duration = _this.player.duration;
                    break;
                case PlayerEvents.PLAYER_PAUSE:
                    _this.isPlaying = false;
                    _this.stopPlayerTimeController();
                    break;
                case PlayerEvents.PLAYER_PLAY:
                    _this.isPlaying = true;
                    _this.startPlayerTimeController();
                    break;
                case PlayerEvents.PLAYER_ERROR_SUBSCRIPTION:
                    _this.isCanPlay = false;
                    break;
                case PlayerEvents.PLAYER_ERROR_LOGIN:
                    _this.isCanPlay = false;
                    break;
                default:
                    break;
            }
        });
    };
    VideoPlayerComponent.prototype.startPlayerTimeController = function () {
        var _this = this;
        this.playerTimeController = setInterval(function () {
            _this.currentTime = _this.player.currentTime;
        }, 1000);
    };
    VideoPlayerComponent.prototype.stopPlayerTimeController = function () {
        clearTimeout(this.playerTimeController);
    };
    VideoPlayerComponent.prototype.ngOnDestroy = function () {
        if (this.playerTimeController) {
            this.stopPlayerTimeController();
        }
        if (this.playerEventsSubscriber) {
            this.playerEventsSubscriber.unsubscribe();
        }
        if (this.activatedRouteSubscriber) {
            this.activatedRouteSubscriber.unsubscribe();
        }
    };
    return VideoPlayerComponent;
}());
export { VideoPlayerComponent };
