import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AnalyticService } from './../../../services/analytic.service';
// import { DataService } from './../../../services/data.service';
// import { Payment, FeedbackData } from './../../../models/user';
import { ModalController } from './../../../services/modal.controller';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
// import { FeedbackData, ModalName } from '../../../models';
import { AuthService } from '../../../services';
// import { monthValidator } from '../../../pages/private-client/payment/monthValidator';
// import { yearValidator } from '../../../pages/private-client/payment/yearValidator';
// import * as moment from 'moment';
var ModalBeforeViewComponent = /** @class */ (function () {
    function ModalBeforeViewComponent(modalController, authService, analyticService, router) {
        var _this = this;
        this.modalController = modalController;
        this.authService = authService;
        this.analyticService = analyticService;
        this.router = router;
        this.wrongPassword = function () {
            return _this.isPasswordWrong ? { 'wrongPassword': { valid: false } } : null;
        };
    }
    ModalBeforeViewComponent.prototype.ngOnInit = function () {
        this.analyticService.targetSend('presenttestmodal', 63840352);
        this.createFormFields();
        this.createAuthForm();
    };
    ModalBeforeViewComponent.prototype.createFormFields = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.email = new FormControl('', {
                    validators: [Validators.required, Validators.email, Validators.maxLength(50)],
                });
                /* this.phone = new FormControl('', {
                  validators: [
                    Validators.required,
                    Validators.pattern('[\+0-9]+'),
                    Validators.minLength(6),
                    Validators.maxLength(25)
                  ]
                }
                ); */
                this.password = new FormControl('', {
                    validators: [Validators.required, Validators.minLength(6), Validators.maxLength(40), this.wrongPassword]
                });
                return [2 /*return*/];
            });
        });
    };
    ModalBeforeViewComponent.prototype.createAuthForm = function () {
        this.authForm = new FormGroup({
            email: this.email,
            // phone: this.phone
            password: this.password
            /* expiredCardYear: this.expiredCardYear,
            expiredCardMonth: this.expiredCardMonth */
        });
    };
    ModalBeforeViewComponent.prototype.closeModal = function () {
        this.analyticService.targetSend('closetestmodal', 63840604);
        this.modalController.destroy();
    };
    ModalBeforeViewComponent.prototype.stopProp = function (event) {
        if (event) {
            event.stopPropagation();
        }
    };
    ModalBeforeViewComponent.prototype.enter = function () {
        var _this = this;
        // this.updateWrongPass(false);
        // this.markFormGroupTouched();
        if (this.authForm.valid) {
            this.analyticService.targetSend('accesstestmodal', 63840577);
            // this.sendInfo();
            this.authService.checkEmail(this.email.value)
                .then(function (res) {
                if (res.exists) {
                    _this.login();
                }
                else {
                    _this.registerUser();
                }
            });
        }
    };
    /* private sendInfo(): void {
      const feedbackData: FeedbackData = {
        email: this.email.value,
        // phone: this.phone.value,
        subject: 'Тест бесплатно',
        message: 'Свяжитесь со мной, пожалуйста!',
        name: 'Noname'
      };
      this.authService.sendFeedback(feedbackData)
        .then(_ => {
          this.modalController.destroy();
          this.modalController.present(ModalName.TEST_REQUEST);
        })
        .catch(() => alert('Что-то пошло не так, попробуйте еще раз.'));
    } */
    /* public onFieldKeyDown(event: Event) {
      // this.expiredCardMonth.setErrors({ incorrect: null });
      // this.expiredCardMonth.updateValueAndValidity();
      const input = event.target as HTMLInputElement;
      if (input.name === 'month' && input.value.length === 2) {
        (document.querySelector('[name="year"]') as HTMLInputElement).focus();
      }
    } */
    /* private createPayment(): void {
      this.analyticService.targetSend('accept30kop', 63161365);
      const currentShortYear = Number(new Date().getFullYear().toString().substr(2, 2));
      if (+this.expiredCardYear.value === currentShortYear) {
        const currentMonth = new Date().getMonth() + 1;
        if (currentMonth < +this.expiredCardMonth.value) {
          const expiredUnixDate = moment(`${this.expiredCardMonth.value}-${this.expiredCardYear.value}`, 'MM-YY').unix();
          this.standartCheck(expiredUnixDate);
        } else {
          this.expiredCardMonth.setErrors({ incorrect: true });
          return;
        }
      } else {
        const expiredUnixDate = moment(`${this.expiredCardMonth.value}-${this.expiredCardYear.value}`, 'MM-YY').unix();
        this.standartCheck(expiredUnixDate);
      }
    } */
    /* private standartCheck(expiredDate?: number): void {
      const payment: Payment = {
        product_option_id: [193],
        pay_sys: 'assist'
      };
      this.authService.createPayment(payment, expiredDate).then(res => {
        if (res.payment_url && res.payment_url.length > 0) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.target = '_blank';
          a.setAttribute('style', 'display: none');
          a.href = res.payment_url;
          a.click();
          a.remove();
        }
      });
    } */
    ModalBeforeViewComponent.prototype.registerUser = function () {
        var _this = this;
        this.authService.register(this.email.value, this.password.value).then(function (_) { return _this.login(); });
    };
    ModalBeforeViewComponent.prototype.login = function () {
        var _this = this;
        this.authService.login(this.email.value, this.password.value)
            .then(function (res) {
            _this.authService.token = res.auth_token;
            _this.authService.user_id = res.user_id;
            _this.authService.changeLoginState(true);
            _this.closeModal();
            // this.createPayment();
            _this.router.navigate(['private/payment'], { queryParams: { id: 259 } });
        })
            .catch(function (_) { return _this.updateWrongPass(true); });
    };
    ModalBeforeViewComponent.prototype.updateWrongPass = function (isWrong) {
        this.isPasswordWrong = isWrong;
        this.password.updateValueAndValidity();
    };
    ModalBeforeViewComponent.prototype.markFormGroupTouched = function () {
        for (var _i = 0, _a = Object.keys(this.authForm.controls); _i < _a.length; _i++) {
            var control = _a[_i];
            this.authForm.controls[control].markAsTouched();
        }
    };
    return ModalBeforeViewComponent;
}());
export { ModalBeforeViewComponent };
