import { FormControl } from '@angular/forms';
import { AuthService } from '../services/auth.service';

let checkTimer: any;

export function emailExistValidator(authService: AuthService) {

  return (control: FormControl) => {
    return new Promise(resolve => {
      clearTimeout(checkTimer);
      checkTimer = setTimeout(() => {
        if (!control.errors) {
          authService.checkEmail(control.value).then(response => {
            if (response.exists) {
              resolve(null);
            } else {
              resolve({
                emailExistValidator: {
                  exist: false
                }
              });
            }
          }).catch();
        }
      }, 2000);
    });
  };

}
