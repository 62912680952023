<div class="modal-wrapper" *ngIf="isShowModal" (click)="closeModal()">
  <app-modal-login *ngIf="isLogin" [isAuthorization]="true"></app-modal-login>
  <app-modal-login *ngIf="isRegistration" [isAuthorization]="false"></app-modal-login>
  <app-modal-info *ngIf="isSendComplete" [message]="completeMessage"></app-modal-info>
  <app-modal-info *ngIf="isSendError" [message]="errorMessage"></app-modal-info>
  <app-modal-info *ngIf="isStockComplete" [message]="istockMesage"></app-modal-info>
  <app-modal-forgot *ngIf="isForgot"></app-modal-forgot>
  <app-vendor-send *ngIf="isVendorSend"></app-vendor-send>
  <app-modal-before-view *ngIf="isFastAuth"></app-modal-before-view>
</div>
