/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./empty-page.component";
var styles_EmptyPageComponent = [];
var RenderType_EmptyPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmptyPageComponent, data: {} });
export { RenderType_EmptyPageComponent as RenderType_EmptyPageComponent };
export function View_EmptyPageComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_EmptyPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-empty-page", [], null, null, null, View_EmptyPageComponent_0, RenderType_EmptyPageComponent)), i0.ɵdid(1, 49152, null, 0, i1.EmptyPageComponent, [], null, null)], null, null); }
var EmptyPageComponentNgFactory = i0.ɵccf("app-empty-page", i1.EmptyPageComponent, View_EmptyPageComponent_Host_0, {}, {}, []);
export { EmptyPageComponentNgFactory as EmptyPageComponentNgFactory };
