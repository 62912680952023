import { ModalName } from '../models/modal';
import { ModalController, AuthService } from '../services';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class RegistrationGuard implements CanActivate {
  constructor(private router: Router, private modalCtrl: ModalController, private authService: AuthService) {}

  canActivate() {
    const isLogin = false;
    if (isLogin) {
      return true;
    } else {
      this.authService.logout();
      this.router.navigate(['/']);
      setTimeout(() => {
        this.modalCtrl.present(ModalName.REGISTER);
      }, 100);
      return false;
    }
  }
}
