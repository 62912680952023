// This service for all features through ass, sorry.

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class SharedService {
  public eduControl: BehaviorSubject<boolean> = new BehaviorSubject(false); // for control edu logo in header

  enableEdu(): void {
    this.eduControl.next(true);
  }

  disableEdu(): void {
    this.eduControl.next(false);
  }
}
