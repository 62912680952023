
  <div class="vendor-send" (click)="stopProp($event)">
    <div class="modal-close" (click)="closeVendorModal()">
      <img src="assets/images/icons/modal-close-icon.svg" alt="" class="modal__close-img">
    </div>
    <span class="vendor-send__title" *ngIf="!vendorFormSuccess"><span class="gradient-text">написать</span> вендору</span>
    <form action="" [formGroup]="vendorForm" (submit)="sendVendor()" *ngIf="!vendorFormSuccess">
      <div class="row">
        <div class="col-6">
          <div class="form-group" [class.form-group_dirty]="vendorName.value">
            <input type="text" id="vendorName" class="form-group__input" [class.has-error]="vendorName.errors"
              formControlName="vendorName">
            <label for="vendorName" class="form-group__label">
              Имя
            </label>
            <div class="field-error" *ngIf="vendorName.errors && (vendorName.dirty || vendorName.touched)">
              <span *ngIf="vendorName.errors['required']" class="form-group__error">Поле обязательное для
                заполнения</span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group" [class.form-group_dirty]="vendorContact.value">
            <input type="text" id="vendorContact" class="form-group__input" [class.has-error]="vendorContact.errors"
              formControlName="vendorContact">
            <label for="vendorContact" class="form-group__label">
              Ваш контактный телефон / E-mail
            </label>
            <div class="field-error" *ngIf="vendorContact.errors && (vendorContact.dirty || vendorContact.touched)">
              <span *ngIf="vendorContact.errors['required']" class="form-group__error">Поле обязательное для
                заполнения</span>
            </div>
          </div>
        </div>

        <!-- <div class="col-6">
          <div class="form-group" [class.form-group_dirty]="vendorShop.value">
            <input type="text" id="vendorShop" class="form-group__input" [class.has-error]="vendorShop.errors"
              formControlName="vendorShop">
            <label for="vendorShop" class="form-group__label">
              Сеть / магазин
            </label>
            <div class="field-error" *ngIf="vendorShop.errors && (vendorShop.dirty || vendorShop.touched)">
              <span *ngIf="vendorShop.errors['required']" class="form-group__error">Поле обязательное для
                заполнения</span>
            </div>
          </div>
        </div> -->

        <div class="col-6">
          <div class="form-group" [class.form-group_dirty]="vendorMessage.value">
            <input type="text" id="vendorMessage" class="form-group__input" [class.has-error]="vendorMessage.errors"
              formControlName="vendorMessage">
            <label for="vendorMessage" class="form-group__label">
              Сообщение
            </label>
            <div class="field-error" *ngIf="vendorMessage.errors && (vendorMessage.dirty || vendorMessage.touched)">
              <span *ngIf="vendorMessage.errors['required']" class="form-group__error">Поле обязательное для
                заполнения</span>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group__btn">
            <button type="submit" class="btn-orange" value="Активировать">
              отправить
            </button>
          </div>
        </div>

      </div>
    </form>
    <div class="vendor-send__success" *ngIf="vendorFormSuccess">
      Благодарим за Ваше обращение, письмо направлено руководителю направления со стороны Persik.<br>Нам очень важна Ваша обратная связь.
    </div>
  </div>

