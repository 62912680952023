import { AuthService } from './../../../services/auth.service';
import { ModalController } from './../../../services/modal.controller';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-send',
  templateUrl: './retail-send.component.html',
  styleUrls: ['./retail-send.component.scss']
})

export class VendorSendComponent implements OnInit {
  public isShowVendorModal = false;

  public vendorName: FormControl;
  public vendorContact: FormControl;
  private vendorShop: FormControl;
  public vendorMessage: FormControl;
  public vendorForm: FormGroup;
  public vendorFormSuccess = false;

  constructor(private modalCtrl: ModalController, private authService: AuthService) {}

  ngOnInit() {
    this.createVendorFormFields();
    this.createVendorForm();
  }

  public stopProp(event: Event): void {
    if (event) {
      event.stopPropagation();
    }
}

  public sendVendor(): void {
    // TODO send vendor
    this.vendorFormSuccess = true;
  }

  public closeVendorModal(): void {
    this.modalCtrl.destroy();
  }

  private createVendorFormFields(): void {
    this.vendorName = new FormControl('', [Validators.required]);
    this.vendorContact = new FormControl('', [Validators.required]);
    this.vendorShop = new FormControl({ value: this.authService.retailData.shop_code, disabled: true});
    this.vendorMessage = new FormControl('', [Validators.required]);
  }

  private createVendorForm(): void {
    this.vendorForm = new FormGroup({
      vendorName: this.vendorName,
      vendorContact: this.vendorContact,
      vendorShop: this.vendorShop,
      vendorMessage: this.vendorMessage
    });
  }
}
