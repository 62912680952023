import { MetaDefinition } from '@angular/platform-browser';
import { MetaData } from '../../models/meta';

const tags: MetaDefinition[] = [
  {
    name: 'keywords',
    content: 'онлайн-просмотр'
  },
  {
    name: 'description',
    content: `Смотрите Persik TV online через интернет. Подключайте телевидение и смотрите более 350 каналов в hd качестве.
      Первый месяц всего за 0,30 руб! Архив 24 дня, Full HD качество, программа передач. Мультирум. info@persik.by`
  }
];

const title = 'Онлайн-просмотр - интернет-телевидение Persik';

export const meta: MetaData = {
  title,
  tags
};
