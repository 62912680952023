import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var SearchService = /** @class */ (function () {
    function SearchService() {
        this.searchEmitter = new Subject();
    }
    Object.defineProperty(SearchService.prototype, "searchPhrase", {
        get: function () {
            return this.phrase;
        },
        set: function (value) {
            if (value && value.length > 2) {
                this.phrase = value;
                this.searchEmitter.next(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    SearchService.ngInjectableDef = i0.defineInjectable({ factory: function SearchService_Factory() { return new SearchService(); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
