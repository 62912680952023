import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { PackageItem } from '../models';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Option } from '../models/tariff';

@Injectable()
export class PaymentService {

  private _tariff: Option;
  private _period: string;
  private _paymentMethods: string[]
  private paymentCheckTimer: any;

  public paymentCompleteEvent: Subject<number> = new Subject();

  constructor(private authService: AuthService) { }

  public setTariff(tariff: Option, period?: string, payments?: string[]): void {
    this._tariff = tariff;
    this._period = period;
    this._paymentMethods = payments;
  }

  public get selectedTariff(): Option {
    return this._tariff;
  }

  public get period(): string {
    return this._period;
  }

  public get paymethods(): string[] {
    return this._paymentMethods;
  }

  public get isHavePackage(): boolean {
    if (this._tariff && this._period) {
      return true;
    }
    return false;
  }

  public setPaymentMethods(payments: string[]): void {
    this._paymentMethods = payments;
    console.log(this._paymentMethods);

  }

  public setPaymentStamp(): void {
    const paymentStamp = {
      count: 0,
      time: moment().unix()
    };
    this.authService.getUserSubscriptions().then(res => {
      if (res && res.length) {
        paymentStamp.count = res.length;
      }
      localStorage.setItem('paymentStamp', JSON.stringify(paymentStamp));
      this.startPaymentCheck();
    });
  }

  public clearPaymentStamp(): void {
    localStorage.removeItem('paymentStamp');
  }

  public startPaymentCheck(): void {
    if (this.authService.isLogin) {
      clearInterval(this.paymentCheckTimer);
      this.paymentCheckTimer = setInterval(() => {
        this.checkPayment();
      }, 60000);
    }
  }

  private get paymentStamp(): PaymentStamp {
    return JSON.parse(localStorage.getItem('paymentStamp'));
  }

  private checkPayment(): void {
    const paymentStamp = this.paymentStamp;
    if (paymentStamp) {
      const currentUnix = moment().unix();
      if (currentUnix - paymentStamp.time > 3600 * 72) {
        this.authService.getUserSubscriptions().then(res => {
          if (res.length > paymentStamp.count) {
            this.paymentCompleteEvent.next(currentUnix);
          }
          this.clearPaymentStamp();
          clearInterval(this.paymentCheckTimer);
        });
      } else {
        this.authService.getUserSubscriptions().then(res => {
          if (res) {
            if (res.length > paymentStamp.count) {
              this.paymentCompleteEvent.next(currentUnix);
              this.clearPaymentStamp();
              clearInterval(this.paymentCheckTimer);
            }
          }
        });
      }
    } else {
      clearInterval(this.paymentCheckTimer);
    }
  }
}


interface PaymentStamp {
  count: number;
  time: number;
}

