import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(private router: Router) { }

  public get isDark(): boolean {
    return !this.router.url.includes('private') &&
      !this.router.url.includes('faq') &&
      !this.router.url.includes('paymethods') &&
      !this.router.url.includes('bank-card') &&
      !this.router.url.includes('watch-free') &&
      !this.router.url.includes('speedtest') &&
      !this.router.url.includes('offer');
  }

  public get isTvGuide(): boolean {
    return this.router.url.includes('tv-guide');
  }

  public goTo(path: string, fragment?: string): void {
    if (fragment) {
      this.router.navigate([path], { fragment });
    } else {
      this.router.navigate([path]);
    }
  }

}
