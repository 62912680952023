import { DataService } from './data.service';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./data.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, dataService) {
        this.http = http;
        this.dataService = dataService;
        this.BASE_URL = 'https://api.persik.by/';
        this.loginState = new Subject();
        this.uuid = '';
        this.authHook = new Subject();
        // window['authHook'] = this.authHook;
    }
    /* setAuthHook(): void {
        this.authHook.subscribe(res => {
          this.getULoginInfo(res.toString());
           this.testUloginPut(res.toString()).then(r => {
            console.log(r);
          });
        });
      } */
    AuthService.prototype.setPhoneCallback = function (phone) {
        var params = new HttpParams().set('phone', phone);
        return this.http
            .post('https://api.persik.by/v2/forms/tariffrequest', params)
            .toPromise();
    };
    /* private getULoginInfo(token: string): Promise<any> {
        const params: HttpParams = new HttpParams().set('token', token).set('action', 'Auth2.connectNetwork');
        return this.http.post('https://persik.by/api.php', params).toPromise();
      } */
    /* private testUloginPut(token: string): Promise<any> {
        const formData: FormData = new FormData();
        formData.append('token', token);
        return this.http.post('v1/account/social', formData).toPromise();
      } */
    AuthService.prototype.checkEmail = function (email) {
        if (email) {
            // const uriEmail = this.convertEmailToURI(email);
            var params = new HttpParams()
                .set('email', email);
            return this.http
                .get(this.BASE_URL.concat('v2/auth/check?'), { params: params })
                .toPromise();
        }
    };
    /* public get isElementRetail(): boolean {
      return this.retailData.shop_code ==
    } */
    AuthService.prototype.getUserPlaylist = function () {
        return this.http
            .get(this.BASE_URL.concat('v2/content/playlisturl'))
            .map(function (res) { return res.url; })
            .toPromise();
    };
    AuthService.prototype.updateUserPlaylist = function () {
        return this.http
            .get(this.BASE_URL.concat('v2/content/updatePlaylistUrl'))
            .map(function (res) { return res.url; })
            .toPromise();
    };
    /* private convertEmailToURI(email: string): string {
      return email.replace(new RegExp(/\+/, 'g'), ' ');
    } */
    AuthService.prototype.login = function (email, password) {
        var params = new HttpParams()
            .set('email', email)
            .set('password', password);
        return this.http
            .post(this.BASE_URL.concat('v1/account/login'), {}, { params: params })
            .toPromise();
    };
    AuthService.prototype.register = function (email, password) {
        var params = new HttpParams()
            .set('email', email)
            .set('password', password);
        return this.http
            .post(this.BASE_URL.concat('v1/account/registration'), {}, { params: params })
            .toPromise();
    };
    AuthService.prototype.clearChannelList = function () {
        this.dataService.channels = [];
    };
    AuthService.prototype.logout = function () {
        this.token = '';
        this.user_id = '';
        this.changeLoginState(false);
        this.clearChannelList();
    };
    AuthService.prototype.changeLoginState = function (isLogin) {
        this.loginState.next(isLogin);
    };
    AuthService.prototype.createPayment = function (payment, cardExpired) {
        var futureUnix;
        var monthCount = 6;
        if (payment.product_option_id) {
            if (+payment.product_option_id[0] === 192 || +payment.product_option_id[0] === 193) {
                monthCount = 12;
            }
        }
        // currentUnix + 170 * 24 * 3600; // Костыль для работы рекурентных платежей
        futureUnix = cardExpired ? cardExpired : moment().add(monthCount, 'month').unix();
        var formData = new FormData();
        formData.append('pay_sys', payment.pay_sys);
        if (payment.litres_item_id) {
            formData.append('litres_item_id[]', "" + payment.litres_item_id.toString());
        }
        else {
            formData.append('product_option_id[]', "" + payment.product_option_id.toString());
        }
        formData.append('card_date', moment.unix(futureUnix).format('YYYY-MM'));
        return this.http
            .post(this.BASE_URL.concat('v2/billing/payment'), formData)
            .toPromise();
    };
    AuthService.prototype.sendFeedback = function (data) {
        var formData = new FormData();
        formData.append('email', data.email);
        formData.append('subject', data.subject);
        formData.append('message', data.message);
        formData.append('name', data.name);
        formData.append('phone', data.phone);
        return this.http
            .post(this.BASE_URL.concat('v2/forms/contacts'), formData)
            .toPromise();
    };
    AuthService.prototype.sendCode = function (data) {
        var formData = new FormData();
        formData.append('code', data.code);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        return this.http
            .post(this.BASE_URL.concat('v2/billing/activateCode'), formData)
            .toPromise();
    };
    Object.defineProperty(AuthService.prototype, "token", {
        get: function () {
            var token = localStorage.getItem('user_token');
            if (token) {
                return token;
            }
            return '';
        },
        set: function (token) {
            if (token.length > 0) {
                localStorage.setItem('user_token', token);
            }
            else {
                localStorage.removeItem('user_token');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "user_id", {
        get: function () {
            var id = localStorage.getItem('user_id');
            if (id) {
                return id;
            }
            return '';
        },
        set: function (user_id) {
            if (user_id.length > 0) {
                localStorage.setItem('user_id', user_id);
            }
            else {
                localStorage.removeItem('user_id');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isLogin", {
        get: function () {
            return this.token.length > 0 && this.user_id.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isRetailLogin", {
        get: function () {
            if (this.retailData) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.getAccountInfo = function () {
        return this.http
            .get(this.BASE_URL.concat('v1/account/info'))
            .toPromise();
    };
    AuthService.prototype.forgotPassword = function (email) {
        /* const params: HttpParams = new HttpParams()
            .set('email', email); */
        var formData = new FormData();
        formData.append('email', email);
        return this.http
            .post(this.BASE_URL.concat('v1/account/forgot'), formData)
            .toPromise();
    };
    AuthService.prototype.setAccountInfo = function (userInfo) {
        var params = new HttpParams()
            .set('email', userInfo.email)
            .set('pass_code', userInfo.pass_code)
            .set('name', userInfo.name)
            .set('sex', userInfo.sex)
            .set('year', (+userInfo.year / 1000).toString())
            .set('phone', userInfo.phone);
        return this.http
            .post(this.BASE_URL.concat('v1/account/info'), {}, { params: params })
            .toPromise();
    };
    AuthService.prototype.getUserSubscriptions = function () {
        return this.http
            .get(this.BASE_URL.concat('v2/billing/subscriptions'))
            .toPromise();
    };
    AuthService.prototype.getTariffs = function () {
        return this.http
            .get(this.BASE_URL.concat('v2/billing/products'))
            .toPromise();
    };
    AuthService.prototype.getAllTariffs = function () {
        return this.http
            .get(this.BASE_URL.concat('v2/billing/productsActive'))
            .toPromise();
    };
    AuthService.prototype.retailLogin = function (name, password) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var params = new HttpParams()
                .set('username', name)
                .set('password', password);
            return _this.http.post(_this.BASE_URL.concat('v2/retail/logon'), {}, { params: params }).toPromise().then(function (response) {
                _this.retailData = response;
                resolve(response);
            }).catch(function (err) {
                console.log('Login error: ', err);
                reject();
            });
        });
    };
    AuthService.prototype.retailLogout = function () {
        this.retailData = null;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.DataService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
