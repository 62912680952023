import { Router } from '@angular/router';
import { AnalyticService } from './../../../services/analytic.service';
// import { DataService } from './../../../services/data.service';
// import { Payment, FeedbackData } from './../../../models/user';
import { ModalController } from './../../../services/modal.controller';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
// import { FeedbackData, ModalName } from '../../../models';
import { AuthService } from '../../../services';
// import { monthValidator } from '../../../pages/private-client/payment/monthValidator';
// import { yearValidator } from '../../../pages/private-client/payment/yearValidator';
// import * as moment from 'moment';


@Component({
  selector: 'app-modal-before-view',
  templateUrl: 'modal-before-view.component.html',
  styleUrls: ['modal-before-view.component.scss']
})

export class ModalBeforeViewComponent implements OnInit {

  public email: FormControl;
  // public phone: FormControl;
  public password: FormControl;
  // public expiredCardMonth: FormControl;
  // public expiredCardYear: FormControl;

  public authForm: FormGroup;

  private isPasswordWrong: boolean;

  public wrongPassword: ValidatorFn = () => {
    return this.isPasswordWrong ? { 'wrongPassword': { valid: false } } : null;
  }

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private analyticService: AnalyticService,
    private router: Router
  ) { }

  ngOnInit() {
    this.analyticService.targetSend('presenttestmodal', 63840352);
    this.createFormFields();
    this.createAuthForm();
  }

  private async createFormFields() {
    this.email = new FormControl('', {
      validators: [Validators.required, Validators.email, Validators.maxLength(50)],
    });

    /* this.phone = new FormControl('', {
      validators: [
        Validators.required,
        Validators.pattern('[\+0-9]+'),
        Validators.minLength(6),
        Validators.maxLength(25)
      ]
    }
    ); */
    this.password = new FormControl('', {
      validators: [Validators.required, Validators.minLength(6), Validators.maxLength(40), this.wrongPassword]
    });
    // this.expiredCardMonth = new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2), monthValidator]);
    // this.expiredCardYear = new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2), yearValidator]);
  }

  private createAuthForm(): void {
    this.authForm = new FormGroup({
      email: this.email,
      // phone: this.phone
      password: this.password
      /* expiredCardYear: this.expiredCardYear,
      expiredCardMonth: this.expiredCardMonth */
    });
  }

  public closeModal(): void {
    this.analyticService.targetSend('closetestmodal', 63840604);
    this.modalController.destroy();
  }

  public stopProp(event: Event): void {
    if (event) {
      event.stopPropagation();
    }
  }

  public enter(): void {
    // this.updateWrongPass(false);
    // this.markFormGroupTouched();
    if (this.authForm.valid) {
      this.analyticService.targetSend('accesstestmodal', 63840577);
      // this.sendInfo();
      this.authService.checkEmail(this.email.value)
        .then(res => {
          if (res.exists) {
            this.login();
          } else {
            this.registerUser();
          }
        });
    }
  }

  /* private sendInfo(): void {
    const feedbackData: FeedbackData = {
      email: this.email.value,
      // phone: this.phone.value,
      subject: 'Тест бесплатно',
      message: 'Свяжитесь со мной, пожалуйста!',
      name: 'Noname'
    };
    this.authService.sendFeedback(feedbackData)
      .then(_ => {
        this.modalController.destroy();
        this.modalController.present(ModalName.TEST_REQUEST);
      })
      .catch(() => alert('Что-то пошло не так, попробуйте еще раз.'));
  } */

  /* public onFieldKeyDown(event: Event) {
    // this.expiredCardMonth.setErrors({ incorrect: null });
    // this.expiredCardMonth.updateValueAndValidity();
    const input = event.target as HTMLInputElement;
    if (input.name === 'month' && input.value.length === 2) {
      (document.querySelector('[name="year"]') as HTMLInputElement).focus();
    }
  } */

  /* private createPayment(): void {
    this.analyticService.targetSend('accept30kop', 63161365);
    const currentShortYear = Number(new Date().getFullYear().toString().substr(2, 2));
    if (+this.expiredCardYear.value === currentShortYear) {
      const currentMonth = new Date().getMonth() + 1;
      if (currentMonth < +this.expiredCardMonth.value) {
        const expiredUnixDate = moment(`${this.expiredCardMonth.value}-${this.expiredCardYear.value}`, 'MM-YY').unix();
        this.standartCheck(expiredUnixDate);
      } else {
        this.expiredCardMonth.setErrors({ incorrect: true });
        return;
      }
    } else {
      const expiredUnixDate = moment(`${this.expiredCardMonth.value}-${this.expiredCardYear.value}`, 'MM-YY').unix();
      this.standartCheck(expiredUnixDate);
    }
  } */

  /* private standartCheck(expiredDate?: number): void {
    const payment: Payment = {
      product_option_id: [193],
      pay_sys: 'assist'
    };
    this.authService.createPayment(payment, expiredDate).then(res => {
      if (res.payment_url && res.payment_url.length > 0) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.target = '_blank';
        a.setAttribute('style', 'display: none');
        a.href = res.payment_url;
        a.click();
        a.remove();
      }
    });
  } */

  private registerUser(): void {
    this.authService.register(this.email.value, this.password.value).then(_ => this.login());
  }

  private login(): void {
    this.authService.login(this.email.value, this.password.value)
      .then(res => {
        this.authService.token = res.auth_token;
        this.authService.user_id = res.user_id;
        this.authService.changeLoginState(true);
        this.closeModal();
        // this.createPayment();
        this.router.navigate(['private/payment'], {queryParams: { id: 259 }});
      })
      .catch(_ => this.updateWrongPass(true));
  }

  private updateWrongPass(isWrong: boolean): void {
    this.isPasswordWrong = isWrong;
    this.password.updateValueAndValidity();
  }

  private markFormGroupTouched() {
    for (const control of Object.keys(this.authForm.controls)) {
      this.authForm.controls[control].markAsTouched();
    }
  }
}
