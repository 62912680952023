import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class SearchService {

  private phrase: string;
  public searchEmitter: Subject<string> = new Subject();

  constructor() {}

  public set searchPhrase(value: string) {
    if (value && value.length > 2) {
      this.phrase = value;
      this.searchEmitter.next(value);
    }
  }

  public get searchPhrase(): string {
    return this.phrase;
  }

}
