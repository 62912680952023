// import { DataService } from './services/data.service';
import { PaymentService } from "./services/payment.service";
import { isPlatformBrowser } from "@angular/common";
import { ModalName } from "./models/modal";
import { Router, NavigationEnd } from "@angular/router";
import { OnInit, OnDestroy, } from "@angular/core";
import { ModalController } from "./services/modal.controller";
import { LoadingService, AuthService } from "./services";
var AppComponent = /** @class */ (function () {
    function AppComponent(router, authService, modalCtrl, loadingService, platform, paymentService) {
        this.router = router;
        this.authService = authService;
        this.modalCtrl = modalCtrl;
        this.loadingService = loadingService;
        this.platform = platform;
        this.paymentService = paymentService;
        this.isModalNav = false;
        this.mobileNavDropdown = {
            online: false,
            "private/devices": false,
            "private/user": false,
        };
    }
    AppComponent.prototype.checkScroll = function () {
        var scrollPosition = window.pageYOffset;
        if (scrollPosition >= 720) {
            this.isHaveMovement = true;
        }
        else {
            this.isHaveMovement = false;
        }
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (isPlatformBrowser(this.platform)) {
            this.isBrowser = true;
            this.addTagScripts();
            /*       const isshowed = localStorage.getItem('issaleshowed');
            if (isshowed === 'newsale') {
              this.isShowSaleModal = false;
            } else {
              this.isShowSaleModal = true;
            } */
            this.checkRegApply();
            this.paymentService.startPaymentCheck();
            this.paymentEventSubscription =
                this.paymentService.paymentCompleteEvent.subscribe(function () {
                    _this.modalCtrl.complete_modal.next(ModalName.PAY_COMPLETE);
                });
            this.loginStateSubscription = this.authService.loginState.subscribe(function () {
                if (_this.authService.isLogin) {
                    _this.paymentService.startPaymentCheck();
                }
            });
            // this.showModalOnce();
        }
        this.loadingStateSubscription = this.loadingService.loadingState.subscribe(function (res) {
            _this.isLoading = res;
        });
        this.routerEventSubscription = this.router.events.subscribe(function (res) {
            if (res instanceof NavigationEnd) {
                if (res.url.includes("player") ||
                    _this.isDark ||
                    res.url.includes("retail")) {
                    document.querySelector("body").classList.add("hidden-jivosite");
                }
                else {
                    document.querySelector("body").classList.remove("hidden-jivosite");
                }
            }
        });
    };
    /* public closeSale(): void {
      this.isShowSaleModal = false;
    } */
    AppComponent.prototype.scrollToTop = function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    Object.defineProperty(AppComponent.prototype, "isTariffsPage", {
        get: function () {
            return this.router.url.includes("tv-tarify");
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.showVendorModal = function () {
        this.modalCtrl.present(ModalName.VENDOR_SEND);
    };
    AppComponent.prototype.toggleMobileDropdown = function (event, item) {
        event.stopPropagation();
        this.mobileNavDropdown[item] = !this.mobileNavDropdown[item];
    };
    Object.defineProperty(AppComponent.prototype, "isActiveTextPages", {
        get: function () {
            return (this.router.url.includes("faq") ||
                this.router.url.includes("paymethods") ||
                this.router.url.includes("free") ||
                this.router.url.includes("offer") ||
                this.router.url.includes("speedtest"));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isActiveDevices", {
        get: function () {
            return this.router.url.includes("devices");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isActiveOnline", {
        get: function () {
            return !this.router.url.includes("private");
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.goTo = function (path, fragment) {
        if (fragment) {
            this.router.navigate([path], { fragment: fragment });
        }
        else {
            this.router.navigate([path]);
        }
    };
    AppComponent.prototype.openMobileNav = function () {
        this.isModalNav = true;
        document.querySelector("body").style.overflow = "hidden";
    };
    AppComponent.prototype.closeMobileNav = function () {
        this.isModalNav = false;
        document.querySelector("body").style.overflow = "auto";
    };
    AppComponent.prototype.toggleMobileNav = function () {
        if (this.isModalNav) {
            this.closeMobileNav();
        }
        else {
            this.openMobileNav();
        }
    };
    Object.defineProperty(AppComponent.prototype, "isDark", {
        get: function () {
            return (!this.router.url.includes("private") &&
                !this.router.url.includes("faq") &&
                !this.router.url.includes("paymethods") &&
                !this.router.url.includes("bank-card") &&
                !this.router.url.includes("watch-free") &&
                !this.router.url.includes("speedtest") &&
                !this.router.url.includes("offer"));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isPlayer", {
        get: function () {
            return this.router.url.includes("player");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isRetailAuth", {
        get: function () {
            return this.router.url.includes("retail/auth");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isRetailPage", {
        get: function () {
            return (this.router.url.includes("retail/main") ||
                this.router.url.includes("retail/stats") ||
                this.router.url.includes("retail/info"));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isPrivateClientPage", {
        get: function () {
            return this.router.url.includes("private") && !this.isCorporateClient;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isCorporateClient", {
        get: function () {
            return this.router.url.includes("istock");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isOnlinePage", {
        get: function () {
            return !this.isPrivateClientPage && !this.isCorporateClient;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isTvGuide", {
        get: function () {
            return this.router.url.includes("tv-guide") && window.innerWidth <= 992;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isRetailAdmin", {
        get: function () {
            return this.router.url.includes("retail/admin");
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.exitRetail = function () {
        this.router.navigate(["/"]);
    };
    AppComponent.prototype.showLoginModal = function () {
        if (this.authService.isLogin) {
            this.router.navigate(["private/user"]);
        }
        else {
            this.modalCtrl.present(ModalName.LOGIN);
        }
    };
    Object.defineProperty(AppComponent.prototype, "isLogin", {
        get: function () {
            return this.authService.isLogin;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.checkRegApply = function () {
        var _this = this;
        var regApply = localStorage.getItem("regApply");
        if (regApply) {
            setTimeout(function () {
                _this.modalCtrl.complete_modal.next(ModalName.REG_COMPLETE);
            }, 100);
        }
    };
    AppComponent.prototype.addTagScripts = function () {
        var script = document.createElement("script");
        script.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n    })(window,document,'script','dataLayer','GTM-P3KWHHN');";
        document.head.appendChild(script);
        var script1 = document.createElement("script");
        script1.innerHTML = "(function(){ var widget_id = 'zcf6rA7PvW';var d=document;var w=window;function l(){\n      var s = document.createElement('script');\n      s.type = 'text/javascript'; s.async = true;\n      s.src = '//code.jivosite.com/script/widget/'+widget_id;\n      var ss = document.getElementsByTagName('script')[0]; ss.parentNode.insertBefore(s, ss);}\n      if(d.readyState=='complete'){l();}else{if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();";
        document.head.appendChild(script1);
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.paymentEventSubscription) {
            this.paymentEventSubscription.unsubscribe();
        }
        if (this.loginStateSubscription) {
            this.loginStateSubscription.unsubscribe();
        }
        if (this.loadingStateSubscription) {
            this.loadingStateSubscription.unsubscribe();
        }
        if (this.routerEventSubscription) {
            this.routerEventSubscription.unsubscribe();
        }
    };
    AppComponent.prototype.showModalOnce = function () {
        var isHave = localStorage.getItem("showmodalonce");
        if (!isHave && !this.router.url.includes("istock")) {
            this.modalCtrl.present(ModalName.FAST_AUTH);
            localStorage.setItem("showmodalonce", "true");
        }
    };
    return AppComponent;
}());
export { AppComponent };
