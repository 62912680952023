import { Injectable, EventEmitter } from '@angular/core';


@Injectable()
export class CheckWidthService {

    public screenWidthEvent: EventEmitter<number> = new EventEmitter();

    constructor() {
      this.detectWidth();
    }

    private detectWidth(): void {
      window.addEventListener('resize', () => {
        this.screenWidthEvent.next(window.innerWidth);
      });
    }

    public getWidth(): number {
        return window.innerWidth;
    }
}
