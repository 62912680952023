import { RegistrationGuard } from './services/registration.guard';
import { CheckWidthService, AnalyticService } from './services';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMetrikaModule } from '@kolkov/ngx-metrika';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FooterComponent } from './components/footer/footer.component';
import { SearchComponent } from './components/search/search.component';

import { ParamInterceptor } from './services/api.interceptor';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { AuthGuard } from './services/auth.guard';

import { NgSelectModule } from '@ng-select/ng-select';

import { YaMetrikaModalComponent } from './components/ya-metrika/ya-metrika.component';
import { ModalWrapperModule } from './components/modal-wrapper/modal-wrapper.module';
import { MainChannelCardModule } from './components/main-channel-card/main-channel-card.module';

import { ShareButtonsModule } from '@ngx-share/buttons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TransferHttpCacheModule } from '@nguniversal/common';

import {
  TranslitService,
  ModalController,
  RouterBackService,
  PaymentService,
  MetaService,
  SharedService
} from './services';

import {
  EmptyPageComponent
} from './pages';
import { LoadingService } from './services/loading.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxMaskModule } from 'ngx-mask';
// import { SaleModalComponent } from './components/sale-modal/sale-modal.component';
import { LocalStorageService } from './services/media-state/local-storage.service';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    EmptyPageComponent,
    YaMetrikaModalComponent,
    FooterComponent,
    SearchComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TransferHttpCacheModule,
    ModalWrapperModule,
    FormsModule,
    MainChannelCardModule,
    NgSelectModule,
    NgxMetrikaModule.forRoot({
      id: 27892269,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    }),
    NgxMaskModule.forRoot(),
    ShareButtonsModule,
    FontAwesomeModule
],
  providers: [
    ModalController,
    TranslitService,
    RouterBackService,
    PaymentService,
    LoadingService,
    CheckWidthService,
    MetaService,
    AuthGuard,
    RegistrationGuard,
    AnalyticService,
    SharedService,
    LocalStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ParamInterceptor,
      multi: true
    },
    /* {
      provide: MEDIA_STATE_PROVIDER,
      useValue: 'localStorage'
    },
    {
      provide: MediaStateManager,
      useFactory: mediaStateFactory,
      deps: [Injector],
      multi: false,
    }, */
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
