<div class="fast-auth" (click)="stopProp($event)">
  <div class="modal-close" (click)="closeModal()">
    <img
      src="assets/images/icons/modal-close-icon.svg"
      alt=""
      class="modal-close__img"
    />
  </div>

  <span class="fast-auth__title">30 дней за 30 копеек</span>
  <!-- <span class="fast-auth__subtitle">пакет за 30 копеек!</span> -->

  <div class="advantages">
    <div class="advantages__item">
      <div class="circle">
        <img
          class="circle__image"
          src="assets/images/icons/monitor.jpg"
          alt=""
        />
      </div>
      <span class="title">100+</span>
      <span class="subtitle">каналов</span>
    </div>

    <div class="advantages__item">
      <div class="circle">
        <img class="circle__image" src="assets/images/icons/movie.jpg" alt="" />
      </div>
      <span class="title">10000+</span>
      <span class="subtitle">фильмов</span>
    </div>

    <div class="advantages__item">
      <div class="circle">
        <img
          class="circle__image"
          src="assets/images/icons/period.jpg"
          alt=""
        />
      </div>
      <span class="title">20 дней</span>
      <span class="subtitle">архив передач</span>
    </div>
  </div>

  <span class="title">Введите Ваш email и пароль</span>

  <form [formGroup]="authForm" novalidate class="form">
    <input
      formControlName="email"
      class="form__input"
      [class.form__input_error]="email.errors && email.touched"
      type="text"
      placeholder="Email"
    />
    <div class="form__errors" *ngIf="email.errors && email.touched">
      <span class="form__errors-item" *ngIf="email.errors['required']"
        >Поле обязательное для заполнения</span
      >
      <span class="form__errors-item" *ngIf="email.errors['email']"
        >Введите корректный email</span
      >
      <span class="form__errors-item" *ngIf="email.errors['maxlength']"
        >Длина не более
        {{ email.errors["maxlength"].requiredLength }} символов</span
      >
    </div>

    <input
      formControlName="password"
      class="form__input"
      [class.form__input_error]="password.errors && password.touched"
      type="password"
      placeholder="Пароль"
    />
    <div class="form__errors" *ngIf="password.errors && password.touched">
      <span class="form__errors-item" *ngIf="password.errors['required']"
        >Поле обязательное для заполнения</span
      >
      <span class="form__errors-item" *ngIf="password.errors['maxlength']"
        >Длина не более
        {{ password.errors["maxlength"].requiredLength }} символов</span
      >
      <span class="form__errors-item" *ngIf="password.errors['minlength']"
        >Длина не менее
        {{ password.errors["minlength"].requiredLength }} символов</span
      >
      <span class="form__errors-item" *ngIf="password.errors['wrongPassword']"
        >Неверный пароль</span
      >
    </div>
    <!--
    <div class="paycard">
      <img class="paycard__image" src="assets/images/card.png" alt="">

      <div class="paycard__fields">
        <span class="paycard__fields-text">Срок действия карты</span>

        <div class="inputs">
          <input class="form__input" name="month" [class.form__input_error]="expiredCardMonth.errors && expiredCardMonth.touched" (keyup)="onFieldKeyDown($event)" formControlName="expiredCardMonth" placeholder="ММ" type="text"> /
          <input class="form__input" name="year" [class.form__input_error]="expiredCardYear.errors && expiredCardYear.touched" (keyup)="onFieldKeyDown($event)" formControlName="expiredCardYear" placeholder="ГГ" type="text">
          <span *ngIf="(expiredCardMonth.errors || expiredCardYear.errors) && (expiredCardMonth.touched || expiredCardYear.touched)" class="inputs__error">Срок действия карты некорректный</span>
        </div>
      </div>
    </div> -->
  </form>

  <div class="button" (click)="enter()">Активировать просмотр</div>

  <span class="small-text">
    Нажимая на кнопку «Активировать просмотр» вы принимаете условия
    <a routerLink="offer" (click)="closeModal()"
      >Пользовательского соглашения</a
    >
  </span>
</div>
