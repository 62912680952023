import { SharedService } from "./../../services/shared.service";
import { OnInit, ElementRef, AfterViewInit, } from "@angular/core";
import { TranslitService, MetaService } from "../../services";
import { meta } from "./metadata";
import { Router } from "@angular/router";
var OnlineComponent = /** @class */ (function () {
    function OnlineComponent(transliteService, metaService, sharedService, router) {
        this.transliteService = transliteService;
        this.metaService = metaService;
        this.sharedService = sharedService;
        this.router = router;
        this.scrollbarOptions = { axis: "y", theme: "minimal-dark" };
        this.menuItems = [
            {
                chapter: "ТВ Онлайн",
                link: "",
                items: [],
            },
            {
                chapter: "Программа передач",
                link: "/tv-guide",
                items: [],
            },
            {
                chapter: "Фильмы",
                link: "/films",
                items: [],
            },
            {
                chapter: "Сериалы",
                link: "/serialy",
                items: [],
            },
            {
                chapter: "Мультфильмы",
                link: "/multfilmy",
                items: [],
            },
            {
                chapter: "Передачи",
                link: "/peredachi",
                items: [],
            },
            {
                chapter: "Блоги",
                link: "/blogers",
                items: [],
            },
        ];
    }
    OnlineComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.updateMeta(meta);
        this.sharedService.eduControl.subscribe(function (res) { return (_this.isCoursesPage = res); });
    };
    Object.defineProperty(OnlineComponent.prototype, "isMain", {
        get: function () {
            return this.router.url === "/" || this.router.url.includes("details");
        },
        enumerable: true,
        configurable: true
    });
    OnlineComponent.prototype.ngAfterViewInit = function () {
        this.elementPosition = this.menuElement.nativeElement.offsetTop;
    };
    OnlineComponent.prototype.handleScroll = function () {
        var windowScroll = window.pageYOffset;
        if (windowScroll >= this.elementPosition) {
            this.stickyHeader = true;
        }
        else {
            this.stickyHeader = false;
        }
    };
    /* public get isCoursesPage(): boolean {
      return this.router.url.includes('kursy-online');
    } */
    OnlineComponent.prototype.getIsChapterSelected = function (items) {
        var _this = this;
        return items.some(function (item) { return _this.router.url.includes(item.link); });
    };
    OnlineComponent.prototype.getTransliteGenreName = function (text) {
        return this.transliteService.convertCyrillToLatin(text);
    };
    OnlineComponent.prototype.stopProp = function (event) {
        event.stopPropagation();
    };
    return OnlineComponent;
}());
export { OnlineComponent };
