/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./search.component";
import * as i5 from "../../services/search.service";
import * as i6 from "@angular/router";
var styles_SearchComponent = [i0.styles];
var RenderType_SearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchComponent, data: {} });
export { RenderType_SearchComponent as RenderType_SearchComponent };
function View_SearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "\u041F\u043E\u0438\u0441\u043A"], ["class", "search__icon"], ["src", "assets/images/icons/search-icon.svg"]], null, null, null, null, null))], null, null); }
function View_SearchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "\u041F\u043E\u0438\u0441\u043A"], ["class", "search__icon"], ["src", "assets/images/icons/search-icon-white.svg"]], null, null, null, null, null))], null, null); }
export function View_SearchComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { searchInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "search search_dark"]], [[2, "search_open", null], [2, "search_light", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickedInside($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "search__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "search__main-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["searchInput", 1]], null, 5, "input", [["class", "search__input"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup.enter"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.inputValue = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.enter" === en)) {
        var pd_5 = (_co.makeSearch() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(11, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(13, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "search__clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.isDark || _co.isOpen); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.isDark && !_co.isOpen); _ck(_v, 7, 0, currVal_3); var currVal_11 = _co.inputValue; _ck(_v, 11, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOpen; var currVal_1 = !_co.isDark; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 13).ngClassValid; var currVal_9 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_SearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-component", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clickedOutside($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_SearchComponent_0, RenderType_SearchComponent)), i1.ɵdid(1, 245760, null, 0, i4.SearchComponent, [i5.SearchService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchComponentNgFactory = i1.ɵccf("app-search-component", i4.SearchComponent, View_SearchComponent_Host_0, { isDark: "isDark" }, {}, []);
export { SearchComponentNgFactory as SearchComponentNgFactory };
