import { Video, VodSections, VodSource, VideoInterface, Country, CountriesBackend, FeaturedChannelsTape } from './../models';
import { Injectable } from '@angular/core';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Channel,
  FeatureChannel,
  Genre,
  Persons,
  Person,
  Tvshow,
  SearchData,
  RatingFilter,
  Banner,
  ChannelStreamData,
  VideoStreamData,
  Category,
  Channels
} from '../models';
import { TimeService } from './time.service';
import { TariffData } from '../models/tariff';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataService {

  private channelGenresCache: Promise<Genre[]>;

  constructor(private http: HttpClient, private timeService: TimeService) { }

  private readonly BASE_URL = 'https://api.persik.by/';

  public channels: Channel[] = [];
  private banners: Banner[] = [];
  private vodCategories: Category[] = [];

  private loadChannels(): Promise<Channel[]> {
    return this.http.get<ChannelsArray>(this.BASE_URL.concat('v2/content/channels')).map(res => res.channels).toPromise();
  }

  getChannels(): Promise<Channel[]> {
    return new Promise((resolve) => {
      if (this.channels.length > 0) {
        resolve(this.channels);
      } else {
        this.loadChannels().then(res => {
          this.channels = res.sort((a, b) => {
            return b.rank - a.rank;
          });
          resolve(this.channels);
        });
      }
    });
  }

  getChannelsForFeatured(genres: Genre[]): Promise<FeaturedChannelsTape[]> {
    return new Promise(resolve => {
      if (genres.length > 0) {
        const fChs: FeaturedChannelsTape[] = [];
        this.getChannels().then(chs => {
          for (let i = 0; i < genres.length; i++) {
            const channels = chs.filter(ch => ch.genres.includes(genres[i].id));
            if (channels && channels.length > 0) {
              fChs.push({
                title: genres[i].name,
                channels
              });
            }
          }
          resolve(fChs);
        });
      } else {
        resolve(null);
      }
    });
  }

  loadFeatureChannels(): Promise<FeatureChannel[]> {
    return this.http.get<FeaturedChannels>(this.BASE_URL.concat('v2/featured/channels?size=10')).map(res => res.channels).toPromise();
  }

  /* loadChannelCategories(): Promise<Genre[]> {
    return new Promise(resolve => {
      if (this.channelCategories.length > 0) {
        resolve(this.channelCategories);
      } else {
        this.http.get(this.BASE_URL.concat('v2/categories/channel')).map((categories: Genre[]) => {
          categories.unshift({
            id: -1,
            name: 'Все жанры',
            name_en: null,
            is_main: true
          });
          const existCategories: Genre[] = categories.filter(category => category.is_main);
          return existCategories;
        }).toPromise().then(res => {
          this.channelCategories = res;
          resolve(this.channelCategories);
        });
      }
    });
  } */

  loadChannelCategories(): Promise<Genre[]> {
    if (!this.channelGenresCache) {
      this.channelGenresCache = this.http.get<Genre[]>(this.BASE_URL.concat('v2/categories/channel')).pipe(
        publishReplay(1),
        refCount()
      ).pipe(map(data => data.filter(genre => genre.is_main))).pipe(map(data => {
        data.unshift({
          id: 1,
          name: 'Все жанры',
          name_en: null,
          is_main: true
        });
        return data;
      })).toPromise();
    }
    return this.channelGenresCache;
  }

  getCurrentTvShow(channelId: number): Promise<Tvshow> {
    const params: HttpParams = new HttpParams()
      .set('channels[]', channelId.toString());
    return this.http.get<TvshowsInterface>(this.BASE_URL.concat('v2/epg/onair'), { params }).pipe(map(response => {
      return response.tvshows[0];
    })).toPromise();
  }

  searchContent(text: string): Promise<SearchData> {
    const params: HttpParams = new HttpParams()
      .set('query', text)
      .set('channels', 'true')
      .set('videos', 'true')
      .set('tvshows', 'true');
    return this.http.get<SearchData>(this.BASE_URL.concat('v2/search'), { params }).toPromise();
  }

  getTvshows(channelId: number): Promise<Tvshow[]> {
    const params: HttpParams = new HttpParams().set('channels[]', channelId.toString()).set('limit', '1000000');
    return this.http.get<TvshowsInterface>(this.BASE_URL.concat('v2/epg/tvshows'), { params })
      .pipe(map(response => response.tvshows.items))
      .toPromise();
  }

  getTodayTvshows(channelId: number): Promise<Tvshow[]> {
    const date: string = this.timeService.getDate();
    const lateDate: string = this.timeService.getThreeDaysAgoDate();
    const params: HttpParams = new HttpParams()
      .set('channels[]', channelId.toString())
      .set('from', lateDate)
      .set('to', date)
      .set('limit', '15');
    return this.http.get<TvshowsInterface>(this.BASE_URL.concat('v2/epg/tvshows'), { params })
      .pipe(map(response => response.tvshows.items))
      .toPromise();
  }

  getArchiveTvshows(channelId: number, skip: number): Promise<Tvshow[]> {
    const date: string = this.timeService.getDate();
    const lateDate: string = this.timeService.getArchiveDate();
    const time = this.timeService.getCurrentTime();
    const params: HttpParams = new HttpParams()
      .set('channels[]', channelId.toString())
      .set('from', lateDate)
      .set('to', date)
      .set('skip', skip.toString())
      .set('limit', '10000');
    return this.http.get<TvshowsInterface>(this.BASE_URL.concat('v2/epg/tvshows'), { params })
      .pipe(map(response => response.tvshows.items.filter(tvshow => tvshow.start < time && tvshow.stop < time)))
      .toPromise();
  }

  getVideoInfo(id: any): Promise<Video> {
    const params: HttpParams = new HttpParams()
      .set('id[]', id.toString());
    return this.http.get<VideoInformationBackend>(this.BASE_URL.concat('v2/content/video'), { params }).pipe(map(res => {
      if (res && res.videos) {
        return res.videos[0];
      }
      return null;
    })).toPromise();
  }

  getVideosInfo(ids: Array<string>): Promise<Array<Video>> {
    let params: HttpParams = new HttpParams();
    ids.forEach(element => {
      params = params.append('id[]', element);
    });
    return this.http.get<VideoInformationBackend>(this.BASE_URL.concat('v2/content/video'), { params }).pipe(map(res => {
      if (res && res.videos) {
        return res.videos;
      }
      return null;
    })).toPromise();
  }

  getSimilar(id: string): Promise<Video[]> {
    const params: HttpParams = new HttpParams()
      .set('id', id.toString());
    return this.http.get<VideoInformationBackend>(this.BASE_URL.concat('v2/featured/similar'), { params }).pipe(map(res => {
      if (res && res.videos) {
        return res.videos;
      }
      return null;
    })).toPromise();
  }

  getTvshowInfo(id: string): Promise<Tvshow> {
    const params: HttpParams = new HttpParams()
      .set('id[]', id);
    return this.http.get<TvshowInformationBackend>(this.BASE_URL.concat('v2/content/tvshow'), { params })
      .pipe(map(res => res.tvshows[0])).toPromise();
  }

  loadVodFeatured(): Promise<VodSections> {
    return this.http.get<VodSections>(this.BASE_URL.concat('v2/featured')).map(featured => {
      featured.sections.forEach(section => {
        this.loadVodHomeContent(section.source).then(res => {
          // res.videos.push({ video_id: -1 });
          Object.assign(section, res);
        });
      });
      return featured;
    }).toPromise();
  }

  private loadVodHomeContent(source: VodSource): Promise<VideoInterface> {
    const params: HttpParams = new HttpParams()
      .set('size', source.params.size.toString())
      .set('category_id', source.params.category_id ? source.params.category_id.toString() : '')
      .set('genre_id', source.params.genre_id ? source.params.genre_id.toString() : '')
      .set('sort', 'last');
    return this.http.get<VideoInterface>(this.BASE_URL.concat(`v${source.version}/${source.module}/${source.action}`), { params })
      .toPromise();
  }

  getChannelById(id: number): Promise<Channel> {
    return new Promise(resolve => {
      if (this.channels.length > 0) {
        const channel: Channel = this.channels.find(ch => ch.channel_id === id);
        if (channel) {
          resolve(channel);
        } else {
          this.loadChannels().then(res => {
            this.channels = res;
            const ch_f: Channel = this.channels.find(ch => ch.channel_id === id);
            resolve(ch_f);
          });
        }
      } else {
        this.loadChannels().then(res => {
          this.channels = res;
          const ch_f: Channel = this.channels.find(ch => ch.channel_id === id);
          resolve(ch_f);
        });
      }
    });
  }

  loadChannel(id: number): Promise<Channel> {
    const paramString = `id[]=${id}`;
    return this.http.get<Channels>(this.BASE_URL.concat('v2/content/channel?', paramString)).map(res => res.channels[0]).toPromise();
  }

  loadVodCategories(): Promise<Category[]> {
    return new Promise(resolve => {
      if (this.vodCategories.length > 0) {
        resolve(this.vodCategories);
      } else {
        this.http.get(this.BASE_URL.concat('v2/categories/video')).map((categories: Category[]) => {
          categories.forEach(category => {
            if (category.id === 6) {
              category.genres.unshift({
                id: 1,
                name: 'Все жанры',
                name_en: null,
                is_main: true
              });
            }
          });
          return categories;
        }).toPromise().then(res => {
          this.vodCategories = res;
          resolve(this.vodCategories);
        });
      }
    });
  }

  loadActors(ids: number[]): Promise<Person[]> {
    let paramString = '';
    ids.forEach(id => {
      paramString += `id[]=${id}&`;
    });
    paramString.slice(-1);
    return this.http.get<Persons>(this.BASE_URL.concat('v2/content/person?', paramString)).pipe(map(response => {
      return response.persons;
    })).toPromise();
  }

  getVideoContent(category_id: number, genre_id: number, size?: number,
    skip?: number, country?: Country, sort?: RatingFilter): Promise<VideoInterface> {
    let type = 'sort';
    if (sort && sort.value.split(' ').length === 2) {
      type = 'year';
    }
    const params: HttpParams = new HttpParams()
      .set('size', (size && size !== 0) ? size.toString() : '')
      .set('offset', (skip && skip !== 0) ? skip.toString() : '')
      .set('category_id', category_id.toString())
      .set('genre_id', (genre_id !== 1 && genre_id) ? genre_id.toString() : '')
      .set('country_id', (country && country.id !== 0) ? country.id.toString() : '')
      .set('sort', (type === 'sort' && sort && sort.value) ? sort.value : 'last')
      .set('year_from', (type === 'year') ? sort.value.split(' ')[0] : '')
      .set('year_to', (type === 'year') ? sort.value.split(' ')[1] : '');
    return this.http.get<VideoInterface>(this.BASE_URL.concat('v2/content/videos'), { params }).toPromise();
  }

  getChannelStream(id: number): Promise<ChannelStreamData> {
    const params: HttpParams = new HttpParams().set('id', id.toString());
    return this.http.get<ChannelStreamData>(this.BASE_URL.concat('v1/stream/channel'), { params }).toPromise();
  }

  getVideoStream(id: number): Promise<VideoStreamData> {
    const params: HttpParams = new HttpParams().set('id', id.toString());
    return this.http.get<VideoStreamData>(this.BASE_URL.concat('v1/stream/video'), { params }).toPromise();
  }

  getTvshowStream(id: string): Promise<ChannelStreamData> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.http.get<ChannelStreamData>(this.BASE_URL.concat('v1/stream/show'), { params }).toPromise();
  }

  getBanners(): Promise<Banner[]> {
    return new Promise(resolve => {
      if (this.banners.length > 0) {
        resolve(this.banners);
      } else {
        this.http.get<Banner[]>(this.BASE_URL.concat('v2/content/banners2')).map(res => {
          if (res) {
            return res.filter(banner => banner.trailer_url_desktop.length > 0);
          }
        }).toPromise().then(response => {
          this.banners = response;
          resolve(this.banners);
        });
      }
    });
  }

  getBannersForBook(): Promise<Banner[]> {
    return this.http.get<Banner[]>(this.BASE_URL.concat('v2/content/banners2'))
      .map(res => res.filter(banner => Number(banner.banner_id) === 121 || Number(banner.banner_id) === 122)).toPromise(); // очередной костыль, пишу и слезы наворачиваются
  }

  getCountryList(category_id: number): Promise<Country[]> {
    const params: HttpParams = new HttpParams().set('id', category_id.toString());
    return this.http.get<CountriesBackend>(this.BASE_URL.concat('v2/content/countries'), { params }).map(response => {
      const countries: Country[] = [];
      countries.push({
        id: 0,
        name: 'Все страны'
      });
      for (const id in response.countries) {
        if (id) {
          countries.push({
            id: +id,
            name: response.countries[id]
          });
        }
      }
      return countries;
    }).toPromise();
  }

  sendPublishFormData(data: PublishFormData): Promise<any> {
    const params: HttpParams = new HttpParams()
      .set('name', data.name)
      .set('email', data.email)
      .set('phone', data.phone)
      .set('message', data.message)
      .set('page', data.page)
      .set('business', data.business ? data.business : '');
    return this.http.post(this.BASE_URL.concat('v2/forms/request'), {}, { params }).toPromise();
  }

  public getTariffs(): Observable<TariffData> {
    return this.http.get<TariffData>(this.BASE_URL.concat('v2/billing/products'));
  }
}

interface ChannelsArray {
  channels: Channel[];
}

interface FeaturedChannels {
  channels: FeatureChannel[];
}

interface TvshowsInterface {
  tvshows: {
    items: Tvshow[]
  };
}

interface VideoInformationBackend {
  videos: Video[];
}

interface TvshowInformationBackend {
  tvshows: Tvshow[];
}

interface PublishFormData {
  name: string;
  email: string;
  phone: string;
  message: string;
  business?: string;
  page: string;
}
