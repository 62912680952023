import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SpeedTestService, MetaService, SpeedData } from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-speed-test-component',
  templateUrl: './speed-test.component.html',
  styleUrls: ['./speed-test.component.scss']
})
export class SpeedTestPageComponent implements OnInit, OnDestroy {
  public speed: SpeedData = {
    tv_speed: 0,
    arch_speed: 0
  };
  public isLoadingTV: boolean;
  public isLoadingArchive: boolean;

  private speedTestSubscriber: Subscription;

  constructor(
    private router: Router,
    private speedTestService: SpeedTestService,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.metaService.setDefaultMeta('Тест скорости');
    this.speedTestSubscriber = this.speedTestService.speed_emitter.subscribe(speed => {
      this.speed = speed;
      this.isLoadingTV = false;
      this.isLoadingArchive = false;
    });
  }

  public goTo(path: string): void {
    this.router.navigate([path]);
  }

  public startTVTest(): void {
    this.isLoadingTV = true;
    this.speed.tv_speed = 0;
    this.speedTestService.checkTVSpeed();
  }

  public startArchiveTest(): void {
    this.isLoadingArchive = true;
    this.speed.arch_speed = 0;
    this.speedTestService.checkArchiveSpeed();
  }

  ngOnDestroy() {
    if (this.speedTestSubscriber) {
      this.speedTestSubscriber.unsubscribe();
    }
  }
}
