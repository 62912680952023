import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { RegistrationGuard } from './services/registration.guard';

import {
  EmptyPageComponent
} from './pages';


const routes: Routes = [
  {
    path: 'private',
    loadChildren: './pages/private-client/private-client.module#PrivateClientModule'
  },
  {
    path: 'client/code',
    redirectTo: 'private/client/code',
    pathMatch: 'full'
  },
  // {
  //   path: 'retail/auth',
  //   component: RetailAuthPageComponent
  // },
  // {
  //   path: 'retail/main',
  //   component: RetailMainPageComponent,
  //   canActivate: [RetailAuthGuard]
  // },
  // {
  //   path: 'retail/stats',
  //   component: RetailStatsPageComponent,
  //   canActivate: [RetailAuthGuard]
  // },
  // {
  //   path: 'retail/info',
  //   component: RetailInfoPageComponent,
  //   canActivate: [RetailAuthGuard]
  // },
  // {
  //   path: 'retail/admin',
  //   component: RetailAdminPageComponent,
  //   canActivate: [RetailAuthGuard]
  // },
  // {
  //   path: 'retail/admin-acts',
  //   component: RetailAdminActsPageComponent,
  //   canActivate: [RetailAuthGuard]
  // },
  {
    path: '',
    loadChildren: './pages/online/online.module#OnlineModule'
  },
  {
    path: 'registration',
    component: EmptyPageComponent,
    canActivate: [RegistrationGuard]
  },
  {
    path: 'channel-player/:id',
    loadChildren: './pages/channel-player/channel-player.module#ChannelPlayerModule'
  },
  {
    path: 'video-player/:id',
    loadChildren: './pages/video-player/video-player.module#VideoPlayerModule'
  },
  {
    path: 'player/:id',
    loadChildren: './pages/pladform-player/pladform-player.module#PladformPlayerModule'
  },
  {
    path: 'watch-free',
    loadChildren: './pages/free/free.module#FreePageModule'
  },
  {
    path: 'offer',
    loadChildren: './pages/offer/offer.module#OfferPageModule'
  },
  {
    path: 'faq',
    loadChildren: './pages/faq/faq.module#FaqPageModule'
  },
  {
    path: 'paymethods',
    loadChildren: './pages/pay-methods/pay-methods.module#PayMethodsPageModule'
  },
  {
    path: 'speedtest',
    loadChildren: './pages/speed-test/speed-test.module#SpeedTestModule'
  },
  {
    path: 'ott',
    loadChildren: './pages/ott/ott.module#OttModule'
  },
  {
    path: 'iptv',
    loadChildren: './pages/iptv/iptv.module#IptvModule'
  },
  {
    path: 'smarttv',
    loadChildren: './pages/smart-tv/smart-tv.module#SmartTvModule'
  },
  {
    path: 'bank-card',
    loadChildren: './pages/bank-card/bank-card.module#BankCardPageModule'
  },
  {
    path: 'game-player/:id',
    loadChildren: './pages/game-player/game-player.module#GamePlayerModule'
  },
  // OLD URLS REDIRECT
  {
    path: 'info/tariffs',
    redirectTo: 'private/tv-tarify',
    pathMatch: 'full'
  },
  {
    path: 'info/free',
    redirectTo: 'watch-free',
    pathMatch: 'full'
  },
  {
    path: 'info',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/smarttv',
    redirectTo: 'smarttv',
    pathMatch: 'full'
  },
  {
    path: 'info/iptv',
    redirectTo: 'iptv',
    pathMatch: 'full'
  },
  {
    path: 'info/channels',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/speedtest',
    redirectTo: 'speedtest',
    pathMatch: 'full'
  },
  {
    path: 'info/offer',
    redirectTo: 'offer',
    pathMatch: 'full'
  },
  {
    path: 'info/samsungtv',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/pc',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/stb',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/about',
    redirectTo: 'private/main',
    pathMatch: 'full'
  },
  {
    path: 'info/erip',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/faq',
    redirectTo: 'faq',
    pathMatch: 'full'
  },
  {
    path: 'info/paymethods',
    redirectTo: 'paymethods',
    pathMatch: 'full'
  },
  {
    path: 'info/ott',
    redirectTo: 'ott',
    pathMatch: 'full'
  },
  {
    path: 'info/mag250',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/archive',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/aurahd',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/mobiles',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/multiroom',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/allservices',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/bin',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/cards',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'info/alldevices',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/devices',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/apps-windows',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/apps-macos',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/apps-android',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/apps-ios',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/samsung-smart-tv',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/lg-smart-tv',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/sony-smart-tv',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/philips-smart-tv',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/sharp-smart-tv',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/vityaz-horizont-smart-tv',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/android-tv',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'info/lgtv',
    redirectTo: 'devices',
    pathMatch: 'full'
  },
  {
    path: 'user/logon',
    redirectTo: 'private/user',
    pathMatch: 'full'
  },
  {
    path: 'user/subscriptions',
    redirectTo: 'private/user/tv-podpiski',
    pathMatch: 'full'
  },
  {
    path: 'video',
    redirectTo: '/films/',
    pathMatch: 'full'
  },
  {
    path: 'video/series',
    redirectTo: '/serialy/',
    pathMatch: 'full'
  },
  {
    path: 'tv/channels',
    redirectTo: '/peredachi',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/604',
    redirectTo: 'channel-player/name-604',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10003',
    redirectTo: 'channel-player/name-10003',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/1052',
    redirectTo: 'channel-player/name-1052',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/296',
    redirectTo: 'channel-player/name-296',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10350',
    redirectTo: 'channel-player/name-10350',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/454',
    redirectTo: 'channel-player/name-454',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10273',
    redirectTo: 'channel-player/name-10273',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/331',
    redirectTo: 'channel-player/name-331',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10355',
    redirectTo: 'channel-player/name-10355',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/409',
    redirectTo: 'channel-player/name-409',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/602',
    redirectTo: 'channel-player/name-602',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/668',
    redirectTo: 'channel-player/name-668',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10002',
    redirectTo: 'channel-player/name-10002',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/590',
    redirectTo: 'channel-player/name-590',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/213',
    redirectTo: 'channel-player/name-213',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/608',
    redirectTo: 'channel-player/name-608',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10264',
    redirectTo: 'channel-player/name-10264',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/286',
    redirectTo: 'channel-player/name-286',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/433',
    redirectTo: 'channel-player/name-433',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/37',
    redirectTo: 'channel-player/name-37',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/260',
    redirectTo: 'channel-player/name-260',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/305',
    redirectTo: 'channel-player/name-305',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10376',
    redirectTo: 'channel-player/name-10376',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/34',
    redirectTo: 'channel-player/name-34',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/243',
    redirectTo: 'channel-player/name-243',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/394',
    redirectTo: 'channel-player/name-394',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10263',
    redirectTo: 'channel-player/name-10263',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/316',
    redirectTo: 'channel-player/name-316',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10025',
    redirectTo: 'channel-player/name-10025',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/177',
    redirectTo: 'channel-player/name-177',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10260',
    redirectTo: 'channel-player/name-10260',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/246',
    redirectTo: 'channel-player/name-246',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/327',
    redirectTo: 'channel-player/name-327',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/329',
    redirectTo: 'channel-player/name-329',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/45',
    redirectTo: 'channel-player/name-45',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10277',
    redirectTo: 'channel-player/name-10277',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10322',
    redirectTo: 'channel-player/name-10322',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10431',
    redirectTo: 'channel-player/name-10431',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10323',
    redirectTo: 'channel-player/name-10323',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/440',
    redirectTo: 'channel-player/name-440',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10316',
    redirectTo: 'channel-player/name-10316',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10423',
    redirectTo: 'channel-player/name-10423',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/242',
    redirectTo: 'channel-player/name-242',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/30',
    redirectTo: 'channel-player/name-30',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/603',
    redirectTo: 'channel-player/name-603',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10315',
    redirectTo: 'channel-player/name-10315',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10369',
    redirectTo: 'channel-player/name-10369',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10432',
    redirectTo: 'channel-player/name-10432',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/270',
    redirectTo: 'channel-player/name-270',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/902',
    redirectTo: 'channel-player/name-902',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/946',
    redirectTo: 'channel-player/name-946',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10225',
    redirectTo: 'channel-player/name-10225',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10262',
    redirectTo: 'channel-player/name-10262',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10278',
    redirectTo: 'channel-player/name-10278',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10314',
    redirectTo: 'channel-player/name-10314',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10395',
    redirectTo: 'channel-player/name-10395',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10430',
    redirectTo: 'channel-player/name-10430',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/235',
    redirectTo: 'channel-player/name-235',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/244',
    redirectTo: 'channel-player/name-244',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/288',
    redirectTo: 'channel-player/name-288',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/311',
    redirectTo: 'channel-player/name-311',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/351',
    redirectTo: 'channel-player/name-351',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/411',
    redirectTo: 'channel-player/name-411',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/924',
    redirectTo: 'channel-player/name-924',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10027',
    redirectTo: 'channel-player/name-10027',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10313',
    redirectTo: 'channel-player/name-10313',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10368',
    redirectTo: 'channel-player/name-10368',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10419',
    redirectTo: 'channel-player/name-10419',
    pathMatch: 'full'
  },
  {
    path: 'tv/channel/10425',
    redirectTo: 'channel-player/name-10425',
    pathMatch: 'full'
  },
  // 404
  {
    path: '**',
    redirectTo: 'private/404',
    pathMatch: 'full'
  },
  {
    path: '404',
    redirectTo: 'private/404',
    pathMatch: 'full'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
