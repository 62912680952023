<div class="info-modal">
  <!-- <div class="modal-close" (click)="closeModal()">
    <img src="assets/images/icons/modal-close-icon.svg" alt="" class="modal-close__img">
  </div> -->
  <div class="info-modal__text" [innerHtml]="message">
  </div>
  <div class="modal-btns modal-btns_center">
    <button (click)="closeModal()" class="btn-orange">ОК</button>
  </div>
</div>
