import { CommonModule } from '@angular/common';
import { MainChannelCardComponent } from './main-channel-card.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    MainChannelCardComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MainChannelCardComponent
  ]
})

export class MainChannelCardModule {}
