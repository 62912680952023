import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class RouterBackService {

  private _from: string;

  constructor(private router: Router, private location: Location) {}

  set from(value: string) {
    this._from = value;
  }

  goBack(): void {
    if (this._from && this._from.length > 0) {
      this.router.navigate([this._from]);
      this.from = null;
    } else {
      this.location.back();
    }
  }

}
