import { SharedService } from "./../../services/shared.service";
import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { TranslitService, MetaService } from "../../services";
import { meta } from "./metadata";
import { Router } from "@angular/router";

@Component({
  selector: "app-online",
  templateUrl: "./online.component.html",
  styleUrls: ["./online.component.scss"],
})
export class OnlineComponent implements OnInit, AfterViewInit {
  @ViewChild("stickyMenu") menuElement: ElementRef;

  public scrollbarOptions = { axis: "y", theme: "minimal-dark" };

  public stickyHeader: boolean;
  public elementPosition: any;
  public isCoursesPage: boolean;

  constructor(
    private transliteService: TranslitService,
    private metaService: MetaService,
    private sharedService: SharedService,
    private router: Router
  ) {}

  public menuItems: MenuItem[] = [
    {
      chapter: "ТВ Онлайн",
      link: "",
      items: [],
    },
    {
      chapter: "Программа передач",
      link: "/tv-guide",
      items: [],
    },
    {
      chapter: "Фильмы",
      link: "/films",
      items: [],
    },
    {
      chapter: "Сериалы",
      link: "/serialy",
      items: [],
    },
    {
      chapter: "Мультфильмы",
      link: "/multfilmy",
      items: [],
    },
    {
      chapter: "Передачи",
      link: "/peredachi",
      items: [],
    },
    {
      chapter: "Блоги",
      link: "/blogers",
      items: [],
    },
    // {
    //   chapter: "Слушай",
    //   link: "/audio-books",
    //   items: [
    //     {
    //       link: "/audio-books",
    //       name: "Аудиокниги",
    //     },
    //     {
    //       link: "/radio",
    //       name: "Радио из сети интернет",
    //     },
    //   ],
    // },
    // {
    //   chapter: "Читай",
    //   link: "/books",
    //   items: [
    //     {
    //       link: "/books",
    //       name: "Книги",
    //     },
    //   ],
    // },
    // {
    //   chapter: "Обучайся",
    //   link: "/kursy-online",
    //   items: [
    //     {
    //       link: "/kursy-online",
    //       name: "Курсы",
    //     },
    //   ],
    // },
    // {
    //   chapter: "Играй",
    //   link: "/games",
    //   items: [
    //     {
    //       link: "/games",
    //       name: "Игры",
    //     },
    //   ],
    // },
  ];

  ngOnInit() {
    this.metaService.updateMeta(meta);
    this.sharedService.eduControl.subscribe(
      (res) => (this.isCoursesPage = res)
    );
  }

  public get isMain(): boolean {
    return this.router.url === "/" || this.router.url.includes("details");
  }

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener("window:scroll", ["$event"])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.stickyHeader = true;
    } else {
      this.stickyHeader = false;
    }
  }

  /* public get isCoursesPage(): boolean {
    return this.router.url.includes('kursy-online');
  } */

  public getIsChapterSelected(items: MenuCategory[]): boolean {
    return items.some((item) => this.router.url.includes(item.link));
  }

  public getTransliteGenreName(text: string): string {
    return this.transliteService.convertCyrillToLatin(text);
  }

  public stopProp(event: Event): void {
    event.stopPropagation();
  }
}

interface MenuItem {
  chapter: string;
  link: string;
  items: MenuCategory[];
}

interface MenuCategory {
  name: string;
  link: string;
}
