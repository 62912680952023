import { SearchService } from './../../services';
import { Component, Input, ViewChild, ElementRef, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-component',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, OnDestroy {

  @ViewChild('searchInput') searchInput: ElementRef;

  @Input() isDark: boolean;

  public isOpen = false;
  public inputValue: string;

  private searchServiceSubscription: Subscription;

  constructor(private searchService: SearchService, private router: Router) {}

  @HostListener('document:click', ['$event']) clickedOutside() {
    this.closeSearch();
  }

  ngOnInit(): void {
    this.searchServiceSubscription = this.searchService.searchEmitter.subscribe((phrase) => {
      this.inputValue = phrase;
    });
  }

  /* public preSearch(): void {
    if (this.inputValue.length > 2) {
      this.dataService.searchContent(this.inputValue).then(res => {
      });
    }
  } */

  public openSearch(): void {
      if (!this.isOpen) {
        this.isOpen = true;
        this.searchInput.nativeElement.focus();
      } else {
          this.makeSearch();
      }
  }

  private closeSearch(): void {
      this.isOpen = false;
  }

  public clickedInside($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public clearSearch(): void {
    if (this.inputValue && this.inputValue.length > 0) {
        this.inputValue = '';
        this.searchInput.nativeElement.focus();
    } else {
      this.closeSearch();
    }
  }

  public inputKeyEnter(event: KeyboardEvent): void {
      switch (+event.code) {
            case 13:
                this.makeSearch();
                break;
            case 27:
                this.closeSearch();
                break;
            default:
                break;
      }
  }

  public makeSearch(): void {
    if (this.inputValue.length > 2) {
      this.searchService.searchPhrase = this.inputValue;
      this.closeSearch();
      this.router.navigate(['/search-results']);
    }
  }

  ngOnDestroy() {
    if (this.searchServiceSubscription) {
      this.searchServiceSubscription.unsubscribe();
    }
  }
}
