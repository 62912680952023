// import { DataService } from './services/data.service';
import { PaymentService } from "./services/payment.service";
import { isPlatformBrowser } from "@angular/common";
import { ModalName } from "./models/modal";
import { Router, NavigationEnd } from "@angular/router";
import {
  Component,
  Inject,
  PLATFORM_ID,
  OnInit,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { ModalController } from "./services/modal.controller";
import { LoadingService, AuthService } from "./services";
// import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  // public favoriteCount = 0;
  public isBrowser: boolean;
  public isLoading: boolean;
  public isModalNav = false;

  // public isShowSaleModal: boolean;

  private paymentEventSubscription: Subscription;
  private loginStateSubscription: Subscription;
  private loadingStateSubscription: Subscription;
  private routerEventSubscription: Subscription;

  public mobileNavDropdown: any = {
    online: false,
    "private/devices": false,
    "private/user": false,
  };

  public isHaveMovement: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private loadingService: LoadingService,
    @Inject(PLATFORM_ID) private platform,
    private paymentService: PaymentService
  ) {}

  @HostListener("window:scroll", ["$event"])
  checkScroll() {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition >= 720) {
      this.isHaveMovement = true;
    } else {
      this.isHaveMovement = false;
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.isBrowser = true;
      this.addTagScripts();
      /*       const isshowed = localStorage.getItem('issaleshowed');
      if (isshowed === 'newsale') {
        this.isShowSaleModal = false;
      } else {
        this.isShowSaleModal = true;
      } */
      this.checkRegApply();
      this.paymentService.startPaymentCheck();
      this.paymentEventSubscription =
        this.paymentService.paymentCompleteEvent.subscribe(() => {
          this.modalCtrl.complete_modal.next(ModalName.PAY_COMPLETE);
        });
      this.loginStateSubscription = this.authService.loginState.subscribe(
        () => {
          if (this.authService.isLogin) {
            this.paymentService.startPaymentCheck();
          }
        }
      );
      // this.showModalOnce();
    }
    this.loadingStateSubscription = this.loadingService.loadingState.subscribe(
      (res) => {
        this.isLoading = res;
      }
    );

    this.routerEventSubscription = this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        if (
          res.url.includes("player") ||
          this.isDark ||
          res.url.includes("retail")
        ) {
          document.querySelector("body").classList.add("hidden-jivosite");
        } else {
          document.querySelector("body").classList.remove("hidden-jivosite");
        }
      }
    });
  }

  /* public closeSale(): void {
    this.isShowSaleModal = false;
  } */

  public scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  public get isTariffsPage(): boolean {
    return this.router.url.includes("tv-tarify");
  }

  public showVendorModal(): void {
    this.modalCtrl.present(ModalName.VENDOR_SEND);
  }

  public toggleMobileDropdown(event, item): void {
    event.stopPropagation();
    this.mobileNavDropdown[item] = !this.mobileNavDropdown[item];
  }

  public get isActiveTextPages(): boolean {
    return (
      this.router.url.includes("faq") ||
      this.router.url.includes("paymethods") ||
      this.router.url.includes("free") ||
      this.router.url.includes("offer") ||
      this.router.url.includes("speedtest")
    );
  }

  public get isActiveDevices(): boolean {
    return this.router.url.includes("devices");
  }

  public get isActiveOnline(): boolean {
    return !this.router.url.includes("private");
  }

  public goTo(path: string, fragment?: string): void {
    if (fragment) {
      this.router.navigate([path], { fragment });
    } else {
      this.router.navigate([path]);
    }
  }

  public openMobileNav(): void {
    this.isModalNav = true;
    document.querySelector("body").style.overflow = "hidden";
  }

  public closeMobileNav(): void {
    this.isModalNav = false;
    document.querySelector("body").style.overflow = "auto";
  }

  public toggleMobileNav(): void {
    if (this.isModalNav) {
      this.closeMobileNav();
    } else {
      this.openMobileNav();
    }
  }

  public get isDark(): boolean {
    return (
      !this.router.url.includes("private") &&
      !this.router.url.includes("faq") &&
      !this.router.url.includes("paymethods") &&
      !this.router.url.includes("bank-card") &&
      !this.router.url.includes("watch-free") &&
      !this.router.url.includes("speedtest") &&
      !this.router.url.includes("offer")
    );
  }

  public get isPlayer(): boolean {
    return this.router.url.includes("player");
  }

  public get isRetailAuth(): boolean {
    return this.router.url.includes("retail/auth");
  }

  public get isRetailPage(): boolean {
    return (
      this.router.url.includes("retail/main") ||
      this.router.url.includes("retail/stats") ||
      this.router.url.includes("retail/info")
    );
  }

  public get isPrivateClientPage(): boolean {
    return this.router.url.includes("private") && !this.isCorporateClient;
  }

  public get isCorporateClient(): boolean {
    return this.router.url.includes("istock");
  }

  public get isOnlinePage(): boolean {
    return !this.isPrivateClientPage && !this.isCorporateClient;
  }

  public get isTvGuide(): boolean {
    return this.router.url.includes("tv-guide") && window.innerWidth <= 992;
  }

  public get isRetailAdmin(): boolean {
    return this.router.url.includes("retail/admin");
  }

  public exitRetail(): void {
    this.router.navigate(["/"]);
  }

  public showLoginModal(): void {
    if (this.authService.isLogin) {
      this.router.navigate(["private/user"]);
    } else {
      this.modalCtrl.present(ModalName.LOGIN);
    }
  }

  public get isLogin(): boolean {
    return this.authService.isLogin;
  }

  private checkRegApply(): void {
    const regApply = localStorage.getItem("regApply");
    if (regApply) {
      setTimeout(() => {
        this.modalCtrl.complete_modal.next(ModalName.REG_COMPLETE);
      }, 100);
    }
  }

  private addTagScripts(): void {
    const script = document.createElement("script");
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-P3KWHHN');`;
    document.head.appendChild(script);

    const script1 = document.createElement("script");
    script1.innerHTML = `(function(){ var widget_id = 'zcf6rA7PvW';var d=document;var w=window;function l(){
      var s = document.createElement('script');
      s.type = 'text/javascript'; s.async = true;
      s.src = '//code.jivosite.com/script/widget/'+widget_id;
      var ss = document.getElementsByTagName('script')[0]; ss.parentNode.insertBefore(s, ss);}
      if(d.readyState=='complete'){l();}else{if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
    document.head.appendChild(script1);
  }

  ngOnDestroy() {
    if (this.paymentEventSubscription) {
      this.paymentEventSubscription.unsubscribe();
    }
    if (this.loginStateSubscription) {
      this.loginStateSubscription.unsubscribe();
    }
    if (this.loadingStateSubscription) {
      this.loadingStateSubscription.unsubscribe();
    }
    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
    }
  }

  private showModalOnce(): void {
    const isHave = localStorage.getItem("showmodalonce");
    if (!isHave && !this.router.url.includes("istock")) {
      this.modalCtrl.present(ModalName.FAST_AUTH);
      localStorage.setItem("showmodalonce", "true");
    }
  }
}
