import { Injectable } from '@angular/core';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
// declare var gtag;
declare let ga;

@Injectable()

export class AnalyticService {
  constructor(private ym: NgxMetrikaService) {}

  targetSend(target: string, id: number): void {
    this.ym.reachGoal.next({
      target,
      options: {
        params: {
          productId: id
        }
      }
    });
    /* gtag('send', 'event', {
      'event_category': target,
      'event_action': 'Click',
    }); */
    ga('send', {
      hitType: 'event',
      eventCategory: target,
      eventAction: 'Click',
    });
  }
}

