import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { emailCorrectValidator, emailExistValidator } from '../../../validators';
import { AuthService, ModalController } from '../../../services';
import { ModalName } from '../../../models/modal';

@Component({
    selector: 'app-modal-forgot',
    templateUrl: './modal-forgot.component.html',
    styleUrls: ['./modal-forgot.component.scss']
})

export class ModalForgotComponent implements OnInit {

    public email: FormControl;
    public forgotForm: FormGroup;

    public isSuccess: boolean;

    constructor(private authService: AuthService, private modalCtrl: ModalController) {}

    ngOnInit() {
        this.createFormField();
        this.createForm();
    }

    public closeModal(): void {
        this.modalCtrl.destroy();
    }

    public stopProp(event: Event): void {
        if (event) {
          event.stopPropagation();
        }
    }

    public sendEmail(): void {
        this.authService.forgotPassword(this.forgotForm.value.email).then(res => {
            this.isSuccess = true;
        });
    }

    public goBack(event: MouseEvent): void {
        this.stopProp(event);
        this.modalCtrl.present(ModalName.LOGIN);
    }

    private createFormField(): void {
        this.email = new FormControl('', [Validators.required, emailCorrectValidator], emailExistValidator(this.authService));
    }

    private createForm(): void {
        this.forgotForm = new FormGroup({
            email: this.email
        });
    }
}
