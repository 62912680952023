export class Tvshow {
    tvshow_id: string;
    channel_id: number;
    title: string;
    date: string;
    start: number;
    stop: number;
    video_id: string;
    deleted: number;
    ts: number;
    cover?: string;

    constructor() {
        this.title = 'Нет данных';
    }
}
