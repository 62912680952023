import { ModalController } from './../../../services/modal.controller';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})

export class ModalInfoComponent {

  @Input() message: string;

  constructor(private modalCtrl: ModalController) {}

  public closeModal(): void {
    this.modalCtrl.destroy();
  }

}
