import { Subject } from 'rxjs/Subject';
import { DataService, TranslitService, RouterBackService, MetaService } from './../../services';
import 'rxjs/add/operator/map';
import { Channel, ContentName, ControlEvents, VideoControlEventInfo, PlayerEvents } from './../../models';
import { Subscription } from 'rxjs';
import { Component, ViewChild, Inject, PLATFORM_ID, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlayerComponent } from '../../components/player';
import { Location, isPlatformBrowser } from '@angular/common';
import { setContentName } from './metadata';

@Component({
  selector: 'app-channel-player',
  templateUrl: './channel-player.component.html',
  styleUrls: ['./channel-player.component.scss']
})

export class ChannelPlayerComponent implements OnInit, OnDestroy {

  public isPlaying = false;
  public isCanPlay = false;
  public isParentControlNeeded = false;
  public selectedChannelId: number;

  @ViewChild('player') player: PlayerComponent;
  public channel_id: number;
  private checkPinEvent: Subject<boolean> = new Subject();
  private autohideTimer: any;
  public isHideControls = false;
  public isBrowser = false;

  private checkPinSubscriber: Subscription;
  private playerEventsSubscriber: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private translitService: TranslitService,
    private routerBackService: RouterBackService,
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platform,
    private metaService: MetaService
  ) { }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.params;
    const urlSplit: string[] = params['id'].split('-');
    this.channel_id = +urlSplit[urlSplit.length - 1];
    if (isPlatformBrowser(this.platform)) {
      this.isBrowser = true;
      setTimeout(() => {
        this.initPlayerBehaviors();
      }, 1000);
    }
    this.showControlsWithAutohide();
  }

  public showControlsWithAutohide(): void {
    this.isHideControls = false;
    clearTimeout(this.autohideTimer);
    this.autohideTimer = setTimeout(() => {
      this.isHideControls = true;
    }, 5000);
  }

  public get volume(): number {
    if (this.player) {
      return this.player.volume * 100;
    }
    return 0;
  }

  public toggleFullscreen(): void {
    if (this.isFullscreenActive) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  private get isFullscreenActive(): boolean {
    return ((document['fullScreenElement'] && document['fullScreenElement']
      !== null) || document['mozFullScreen'] || document['webkitIsFullScreen']);
  }

  private openFullscreen(): void {
    const elem = document.body;
    const methodToBeInvoked = elem['requestFullscreen'] || elem['mozRequestFullScreen'] || elem['webkitRequestFullscreen'];
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  private closeFullscreen(): void {
    const elem = document;
    const methodToBeInvoked = elem['cancelFullScren'] || elem['mozCancelFullScreen'] || elem['webkitCancelFullScreen'];
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  private initPlayerBehaviors() {                          // Подписка на события плеера
    this.playerEventsSubscriber = this.player.playerEvents.subscribe((event) => {
      switch (event) {
        case PlayerEvents.PLAYER_READY:
          this.isPlaying = true;
          this.isCanPlay = true;
          break;
        case PlayerEvents.PLAYER_PAUSE:
          this.isPlaying = false;
          break;
        case PlayerEvents.PLAYER_PLAY:
          this.isPlaying = true;
          break;
        case PlayerEvents.PLAYER_ERROR_SUBSCRIPTION:
          this.isCanPlay = false;
          break;
        case PlayerEvents.PLAYER_ERROR_LOGIN:
          this.isCanPlay = false;
          break;
        default:
          break;
      }
    });
  }

  public onControlEvent(event: VideoControlEventInfo): void {                     // Подписка на события контрола
    switch (event.name) {
      case ControlEvents.CONTROL_PAUSE:
        this.player.pause();
        break;
      case ControlEvents.CONTROL_PLAY:
        this.player.resume();
        break;
      case ControlEvents.CONTROL_VOLUME:
        this.player.volume = event.value / 100;
        break;
      default:
        break;
    }
  }

  public onAdultCheckerComplete(result: boolean): void {
    if (result) {
      this.checkPinEvent.next(true);
    }
    this.isParentControlNeeded = false;
  }

  public goBack(): void {
    if (this.isFullscreenActive) {
      this.closeFullscreen();
    }
    this.routerBackService.goBack();
  }

  public changeActiveChannel(channel: Channel): void {
    this.channel_id = channel.channel_id;
    this.selectedChannelId = channel.channel_id;
    const cpu: string = this.translitService.convertCyrillToLatin(channel.name);
    this.location.go(`channel-player/${cpu}-${channel.channel_id}`);
    this.player.stop();
    this.playChannel();
  }

  changeSelectedChannel(channel_id: number): void {
    this.selectedChannelId = channel_id;
  }

  private playChannel(): void {
    this.dataService.getChannelById(this.channel_id).then(channel => {
      const meta = setContentName(channel.name);
      this.metaService.updateMeta(meta);
      if (!channel.age_rating.includes('18')) {
        this.isParentControlNeeded = false;
        if (this.checkPinSubscriber) {
          this.checkPinSubscriber.unsubscribe();
        }
        if (channel.genres.includes(1299)) {
          this.player.play(this.channel_id, ContentName.RADIO);
        } else {
          this.player.play(this.channel_id, ContentName.CHANNEL);
        }
      } else {
        this.isParentControlNeeded = true;
        this.checkPinSubscriber = this.checkPinEvent.subscribe(() => {
          this.player.play(this.channel_id, ContentName.CHANNEL);
        });
      }
    });
  }

  ngOnDestroy() {
    this.player.stop();
    if (this.playerEventsSubscriber) {
      this.playerEventsSubscriber.unsubscribe();
    }
    if (this.checkPinSubscriber) {
      this.checkPinSubscriber.unsubscribe();
    }
    this.closeFullscreen();
  }

}
