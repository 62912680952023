import { Router } from '@angular/router';
import { Location } from '@angular/common';
var RouterBackService = /** @class */ (function () {
    function RouterBackService(router, location) {
        this.router = router;
        this.location = location;
    }
    Object.defineProperty(RouterBackService.prototype, "from", {
        set: function (value) {
            this._from = value;
        },
        enumerable: true,
        configurable: true
    });
    RouterBackService.prototype.goBack = function () {
        if (this._from && this._from.length > 0) {
            this.router.navigate([this._from]);
            this.from = null;
        }
        else {
            this.location.back();
        }
    };
    return RouterBackService;
}());
export { RouterBackService };
